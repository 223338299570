import { CustomAppProps } from '@lib/types'
import Auth from './Auth'

const AuthWrapper = ({
  Component,
  pageProps,
}: Pick<CustomAppProps<{}>, 'Component' | 'pageProps'>) => {
  if (Component.auth)
    return (
      <Auth
        // append props
        {...(typeof Component.auth !== 'boolean' ? Component.auth : {})}
      >
        <Component {...pageProps} />
      </Auth>
    )
  return <Component {...pageProps} />
}

export default AuthWrapper
