import * as userMedia from '@utils/userMedia'
import type { Kind } from '@utils/userMedia/types'

/*
Sets the enabled flag of a track, used for muting mic/camera.
https://stackoverflow.com/a/60965529

Setting "enabled" to false will keep tracks active, but audio tracks
aren't audible (sample value 0) and video frame is filled w/ black pixels
*/

export default function enable(
  this: MediaStream | undefined,
  kind: Kind,
  to: boolean | ((prev: boolean) => boolean),
) {
  for (const [trackKind, track] of userMedia.getTracks(this)) {
    if (kind !== trackKind || !track) continue
    track.enabled = typeof to === 'boolean' ? to : to(track.enabled)
  }
}
