import { useTranslation } from '@hooks'
import { cn } from '@utils/css'
import { signIn } from 'next-auth/react'

const Login = ({ className }: { className?: string }) => {
  const { translations } = useTranslation()
  return (
    <div className={cn('flex', className)}>
      <button
        type="button"
        className="bg-white p-1 rounded-md border border-black/10 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
        onClick={() => signIn('auth0')}
      >
        {translations.auth.signIn}
      </button>
    </div>
  )
}

export default Login
