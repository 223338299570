import {
  useConsultationHistory,
  useConsultations,
  useMyConsultations
} from '@hooks/useConsultations'
import usePersistent from '@hooks/usePersistent'
import { usePrescriptions } from '@hooks/usePrescriptions'
import { useUserId } from '@hooks/useSession'
import { ConsultationStatus } from '@lib/types/consultations'
import { PrescriptionStatus } from '@lib/types/prescriptions'

export default function useCounter() {
  const professionalId = useUserId()
  const { data: consultations } = useConsultations([
    ConsultationStatus.Payed,
    ConsultationStatus.Claimed,
  ])
  const { data: myConsultations } = useMyConsultations()

  const { data: prescriptions } = usePrescriptions([PrescriptionStatus.pending])

  // History (TODO: include history for both consultation and prescription)
  const { data: historyCons } = useConsultationHistory(7)
  const { data: historyPres } = usePrescriptions(
    [PrescriptionStatus.approved, PrescriptionStatus.refused],
    (c) => c.professionalId === professionalId,
  )

  const historyServingCons = historyCons?.filter((cons) => {
    return cons.status == ConsultationStatus.Serving
  })

  // intersection between consultations and myConsultations
  const consultationPlusMyConsultation = (consultations?.map(c => c.id) ?? []).concat(
    myConsultations?.map(c => c.id) ?? []
  ).reduce((acc: string[],cur) => {
    return acc.includes(cur) ? acc : acc.concat(cur)
  },[])
  
  return usePersistent({
    consultation: consultations?.length,
    myConsultation: myConsultations?.length,
    consultationPlusMyConsultation: consultationPlusMyConsultation.length,
    prescription: prescriptions?.length,
    history:
      historyCons && historyPres
        ? historyCons.length + historyPres.length
        : historyCons || historyPres
          ? historyCons?.length || historyPres?.length
          : undefined,
    historyServing: historyServingCons?.length,
    historyCounts: {
      consultation: historyCons?.length,
      prescription: historyPres?.length,
    },
  })
}
