import { Icon, Sidebar } from '@drdropin-tech/theseus'
import useTranslation from '@hooks/useTranslation'
import dynamic from 'next/dynamic'
import type { ReactNode } from 'react'
import * as React from 'react'
import { Sticky } from '..'

const Nav = dynamic(() => import('@components/SidebarNav'), { ssr: false })

interface Props {
  children: ReactNode
  sidebarOpen: boolean
  setSidebarOpen: (val: boolean) => void
}

const SidebarLayout = React.memo(function SidebarLayout({
  setSidebarOpen,
  sidebarOpen,
  children,
}: Props) {
  const { translations } = useTranslation()
  return (
    <Sidebar
      useMobile={false}
      className="drawer-mobile-small"
      contentClassName="flex flex-col overflow-x-hidden"
      isOpen={sidebarOpen}
      content={
        <>
          <Sticky
            className="sticky z-40 flex justify-center transition duration-300 -top-px md:hidden"
            stickyClassName="shadow-sm bg-accent/80 backdrop-blur-md"
          >
            <div className="flex items-center justify-between w-full gap-4 py-4 max-w-7xl sm:px-6 md:px-8">
              <button
                type="button"
                className="pr-4 text-gray-500 border-r border-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
                onClick={() => setSidebarOpen(true)}
              >
                <span className="sr-only">{translations.nav.openSidebar}</span>
                <Icon name="Menu" iconSize="md" aria-hidden="true" />
              </button>
            </div>
          </Sticky>

          <div className="relative flex flex-col flex-1 w-full">
            <main className="flex flex-1 h-fit">
              <div className="py-6 px-4 mx-auto sm:px-6 md:px-8 max-w-4xl w-full flex flex-col">
                {children}
              </div>
            </main>
          </div>
        </>
      }
      setOpen={setSidebarOpen}
    >
      <Nav />
    </Sidebar>
  )
})

export default SidebarLayout
