import { useEffect } from 'react'
import verticals from '@lib/verticals'
import { Vertical } from '@lib/types'

const useTheme = (vertical: Vertical) => {
  useEffect(() => {
    setTheme(vertical)
  }, [vertical])
}

// Simple dom manipulation to tap into the body element and override
// page theme on any given page, based on vertical.

const setTheme = (vertical: Vertical = 'general') => {
  if (document) {
    const bodyEl = document.querySelector('body')
    if (bodyEl) {
      bodyEl.classList.remove(...verticals)
      bodyEl.classList.add(vertical)
      bodyEl.dataset.theme = vertical
    }
  }
}

export default useTheme
