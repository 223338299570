import SocketListener from '@components/Sockets/SocketListener'
import config from '@lib/config'
import { SessionStatus } from '@lib/types/auth'
import { useSession } from 'next-auth/react'
import React from 'react'
import useSocketConsultationChannelName from './useSocketConsultationChannelName'
import useTranslation from './useTranslation'

const coreEvents = ['appointmentUpdated', 'appointmentAdded'].map((e) => ({
  name: e,
  target: 'consultations',
}))
const prescriptionEvents = ['prescriptionUpdated', 'prescriptionAdded'].map(
  (e) => ({ name: e, target: 'prescriptions' }),
)
type Props = {
  verbose?: boolean
  hidden?: boolean
}

const useSockets = ({ verbose = true, hidden = true }: Props = {}) => {
  const { translations } = useTranslation()
  const { status } = useSession()
  const consultationChannel = useSocketConsultationChannelName()

  const consultationSocket = React.useMemo(
    () =>
      status === SessionStatus.Authenticated ? (
        <SocketListener
          key={'consultationsSocket'}
          title={translations.nav.consultations}
          url={`${config.coreApi}/v1`}
          events={coreEvents}
          channelName={consultationChannel}
          position={'bottom'}
          hidden={hidden}
          verbose={verbose}
        />
      ) : null,
    [translations, status, verbose, hidden, consultationChannel],
  )
  const prescriptionSocket = React.useMemo(
    () =>
      status === SessionStatus.Authenticated ? (
        <SocketListener
          key={'prescriptionsSocket'}
          title={translations.nav.prescriptions}
          url={`${config.prescriptionWeb}/api`}
          events={prescriptionEvents}
          channelName="private-prescriptions"
          position={'bottom'}
          roles={config.prescriptionRoles} // only allow valid roles access to this socket
          hidden={hidden}
          verbose={verbose}
        />
      ) : null,
    [translations, status, verbose, hidden],
  )

  return [consultationSocket, prescriptionSocket]
}

export default useSockets
