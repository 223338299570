import * as React from 'react'
import type { ReactNode } from 'react'
import useState from './state'
import { createPortal } from 'react-dom'

interface Props {
  children: ReactNode
}

export default function Portal(this: string, props: Props) {
  const isOpen = useState((state) => state.instances[this]?.isOpen)
  const mountSelector = useState((state) => state.instances[this]?.mount)
  const mountElement = mountSelector && document.querySelector(mountSelector)

  if (!isOpen || !mountElement) return null

  return createPortal(<React.Fragment {...props} />, mountElement)
}
