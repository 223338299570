/** @type {import('tailwindcss').Config} */

module.exports = {
  presets: [require('@drdropin-tech/theseus/lib/tw-config.js')],
  content: [
    './src/**/*.{js,jsx,ts,tsx}',
    './node_modules/@drdropin-tech/theseus/**/*.{js,jsx,ts,tsx}',
  ],
  theme: {
    extend: {
      rotate: {
        135: '135deg',
      },
      colors: {
        success: '#34A66E',
        error: '#E5705A',
        'success-light': '#D4F8E7',
        'success-dark': '#2D7859',
        'warning-light': '#FCF0E1',
        'warning-dark': '#815924',
        'error-dark': '#BA3C3C',
        'error-light': '#FEE4E2',
      },
      animation: {
        'slide-down': 'slide-down 0.3s forwards',
        'slide-up': 'slide-up 0.3s forwards',
      },
      keyframes: {
        'slide-down': {
          from: { transform: 'translateY(-100%)' },
          to: { transform: 'translateY(0%)' },
        },
        'slide-up': {
          from: { transform: 'translateY(0%)' },
          to: { transform: 'translateY(-100%)' },
        },
      },

      // TODO: impossible to use ssot for in between breakpoints? Below Causes infinite loop
      // screens: ({ theme }) => ({
      //   narrow: {
      //     min: theme('screens.md'),
      //     max: theme('screens.lg'),
      //   },
      // }),
    },
  },
}
