import * as React from 'react'

export default function useMount(fn: () => void) {
  const ref = React.useRef(fn)
  ref.current = fn

  React.useEffect(() => {
    ref.current()
  }, [])
}

/*
  When using props or state variables inside
  a useEffect which should only trigger on mount,
  exhaustive-deps will complain. including the deps
  negates its function as a lifecycle hook.

  You can either disable the rule (ugly) or
  put whatever variables you need in a ref (uglier)

  This hook resolves above issues, has a cleaner
  signature than useEffect and allows async cb
  because internal effect doesn't return anything
*/
