const sortDevices = (devices: MediaDeviceInfo[]) =>
  devices.reduce<Record<MediaDeviceKind, MediaDeviceInfo[]>>(
    (a, d) => (a[d.kind].push(d), a),
    {
      videoinput: [],
      audioinput: [],
      audiooutput: [],
    },
  )

export default sortDevices
