import { SessionStatus } from '@lib/types/auth'
import { useSession } from 'next-auth/react'
import type { ReactNode } from 'react'
import { useState } from 'react'
import SidebarLayout from './Sidebar'

interface Props {
  pathname: string
  children: ReactNode
}

const Layout = ({ children, pathname }: Props) => {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const { status } = useSession()

  // passthrough for custom next auth landing pages
  const isPassThrough = pathname.startsWith('/auth/')

  // this is the landing page for an unauthenticated user - we need a different layout
  const isLandingPageForUnauthenticatedUser =
    ['/'].includes(pathname) && status !== SessionStatus.Authenticated

  // this is a consultation or prescription - we need a different layout
  const isConsultationOrPrescription = [
    '/consultations/[id]',
    '/prescriptions/[id]',
  ].includes(pathname)

  if (
    isPassThrough ||
    isLandingPageForUnauthenticatedUser ||
    isConsultationOrPrescription
  ) {
    return <>{children}</>
  }

  return (
    <div className="bg-black/5">
      <SidebarLayout sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}>
        {children}
      </SidebarLayout>
    </div>
  )
}

export default Layout
