import { logOut } from '@utils/auth'
import { useEffect, useState } from 'react'
import useInterval from './useInterval'
import { logsink } from '@lib/logsink'
import * as Sentry from '@sentry/nextjs'

const useIdleTime = (
  interval = 60000, // check every minute
  maxIdleTime = 60 * 60 * 1000, // max idle time is 1 hours
) => {
  const [idleTime, setIdleTime] = useState(0)

  // increment idle time
  useInterval(() => {
    setIdleTime((i) => i + interval)
  }, interval)

  // idle checker
  useEffect(() => {
    // throttled
    const resetIdleTime = (() => {
      let waiting = false
      return () => {
        if (!waiting) {
          setIdleTime(0)
          waiting = true
          setTimeout(() => {
            waiting = false
          }, 1000)
        }
      }
    })()
    window.addEventListener('mousemove', resetIdleTime)
    window.addEventListener('keypress', resetIdleTime)
    return () => {
      window.removeEventListener('mousemove', resetIdleTime)
      window.removeEventListener('keypress', resetIdleTime)
    }
  }, [])

  useEffect(() => {
    if (
      idleTime >= maxIdleTime // Handle idle time expiration
    ) {
      Sentry.captureMessage(`User idle time expired ${idleTime} >= ${maxIdleTime} -> logOut`, 'info')
      logsink.info({ idleTime, maxIdleTime }, `User idle time expired`)
      logOut()
    }
  }, [idleTime, maxIdleTime])

  return idleTime
}

export default useIdleTime
