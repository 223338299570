import type { Constraints, CoercedConstraints } from './types'

/*
  Helper function for coercing arguments into
  params satifysing that of native getUserMedia.

  Interpolates devices which typically comes from zustand
  store and always returns <undefined | object> instead
  of possible boolean in native constraints for easier typing
*/

export default function coerceConstraints(
  devices: Partial<Record<'audio' | 'video', string>>,
  constraints: Constraints,
): CoercedConstraints {
  const coerced: CoercedConstraints = {
    video: !!constraints.video ? {} : undefined,
    audio: !!constraints.audio ? {} : undefined,
  }

  if (typeof constraints.video === 'object') coerced.video = constraints.video
  if (typeof constraints.audio === 'object') coerced.audio = constraints.audio

  if (coerced.video) coerced.video.deviceId = devices.video
  if (coerced.audio) coerced.audio.deviceId = devices.audio

  return coerced
}
