'use client';

import { Subject } from "rxjs";
import { catchError, bufferTime, concatMap, distinctUntilChanged, filter, map, tap } from "rxjs/operators";

type Level = 'trace' | 'debug' | 'info' | 'warn' | 'error' | 'fatal';
type Config = {
  level: Level;
}

export const levels: Level[] = ['trace', 'debug', 'info', 'warn', 'error', 'fatal'];
export const config: Config = { level: 'info' }

const hasFetch = typeof window !== 'undefined' && typeof window.fetch !== 'undefined';

const makePostRequest = <Payload>(payload: Payload) => {
  if (!hasFetch) {
    // just fake it
    return Promise.resolve({ ok: true, status: 200, statusText: 'OK' })
  }
  return fetch('/api/logsink', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Content-Encoding': 'gzip',
    },
    body: JSON.stringify(payload),
  })
};

export const createLogWorker = (interval: number) => {

  // create a subject to receive log messages
  const subject$ = new Subject<{
    level: Level;
    message: string;
    ctx: any[];
  }>();

  subject$
    .pipe(
      // two consecutive messages with the same content will be ignored
      distinctUntilChanged((a, b) => a.message === b.message),
      // only send messages that are at or above the configured level
      filter((v) => levels.indexOf(v.level) >= levels.indexOf(config.level)),
      // add a timestamp to each message
      map((v) => ({ ...v, timestamp: new Date().toISOString() })),
      // buffer messages for (interval) second, or max 10 messages, whichever comes first
      bufferTime(interval, null, 10),
      // filter out empty buffers
      filter((v) => v.length > 0),
      // send buffer as a single POST request
      concatMap((v) => makePostRequest(v)),
      // log errors
      tap((v) => {
        if (!v.ok) {
          console.error(`logsink failed with status ${v.status} ${v.statusText}`);
        }
      }),
      catchError((err) => {
        console.error('logsink failed', err);
        return [];
      }),
    ).subscribe();

  return (level: Level, message: string, ctx: any) =>
    subject$.next({ level, message, ...ctx });
};

/**
 * Use this to send logs from the frontend to the nextjs server.
 */
export const $logsink = {
  // flip to false to quiet console output, handy to flip on/off during development
  verbose: false,
  __post: createLogWorker(5000),
  trace<T>(ctx: T, message: string) {
    return this.__post('trace', message, ctx)
  },
  debug<T>(ctx: T, message: string) {
    return this.__post('debug', message, ctx)
  },
  info<T>(ctx: T, message: string) {
    return this.__post('info', message, ctx)
  },
  warn<T>(ctx: T, message: string) {
    return this.__post('warn', message, ctx)
  },
  error<T>(ctx: T, message: string) {
    return this.__post('error', message, ctx)
  },
  fatal<T>(ctx: T, message: string) {
    return this.__post('fatal', message, ctx)
  },
}

type LogsinkMethod = Exclude<keyof typeof $logsink, "__post" | "verbose">;

export const logsink = new Proxy($logsink, {
  get(target, prop: LogsinkMethod) {
    if (prop in target && Reflect.has(target, prop) && typeof Reflect.get(target, prop) === 'function') {
      return <T>(ctx: T, message: string) => {
        verboseLog(prop, message, ctx);
        return Reflect.apply(Reflect.get(target, prop), target, [ctx, message])
      }
    }
  }
})

const styles = {
  blue: 'color: #0579df;',
  yellow: 'color: #f0ad4e;',
  green: 'color: #5cb85c;',
  red: 'color: #d9534f;',
  default: 'color: inherit;',
};

/**
 * This is a helper function to log to the console in a consistent way. With coloring and stuff.
 */
const verboseLog = <C>(method: LogsinkMethod, msg: string, ctx: C) => {
  if (!$logsink.verbose) return;
  const arg$ = () => {
    if (['warn', 'error', 'fatal'].includes(method)) {
      return ["%c%s %c%s %c%s", styles.blue, new Date().toISOString(), styles.red, msg, styles.yellow, JSON.stringify(ctx, null, 2)]
    }
    return ["%c%s %c%s %c%s", styles.blue, new Date().toISOString(), styles.green, msg, styles.yellow, JSON.stringify(ctx, null, 2)]
  };
  switch (method) {
    case 'trace': {
      console.trace.call(console, ...arg$());
      break;
    }
    case 'debug': {
      console.debug.call(console, ...arg$());
      break;
    }
    case 'info': {
      console.log.call(console, ...arg$());
      break;
    }
    case 'warn': {
      console.warn.call(console, ...arg$());
      break;
    }
    case 'error':
    case 'fatal': {
      console.error.call(console, ...arg$());
      break;
    }
  }
};
