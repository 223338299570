/**
 * posthog initialization
 */
import posthog, { PostHog } from 'posthog-js'
import config from './config'
import { postHogConfig } from '../../posthog.config'

declare global {
  interface Window {
    posthog: PostHog
  }
}

const hazWindow = typeof window !== 'undefined'

if (hazWindow && config.posthogEnabled === true) {
  posthog.init(config.posthogApiKey, postHogConfig)
}

export default posthog
