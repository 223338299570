import { useEffect, useState } from 'react'
import { create } from 'zustand'

type UTCDateString = string

interface ExpiryState {
  id: number
  exp: number
  idTokenExpires?: UTCDateString
  expireAt?: Date
}

const store = create<ExpiryState>(() => ({
  id: 0,
  exp: Infinity
}))

const dateFromUTCString = (dateStr: UTCDateString) => new Date(Date.parse(dateStr))

/**
 * remaining seconds until expiry
 * @param exp expiry date
 * @returns remaining seconds
 */
const remaining = (exp: Date) => Math.floor((exp.valueOf() - Date.now()) / 1000)

/**
 * useExpiry hook
 * @param idTokenExpires
 * @param interval
 * @returns expiry state in the form of seconds remaining on every ticks specified by interval
 */
export const useExpiry = (idTokenExpires?: UTCDateString, interval = 5000) => {

  const [state, setState] = useState(store.getState())

  useEffect(() => {
    store.subscribe((s) => setState(s))
  }, [])
  useEffect(() => {
    if (typeof window === 'undefined' || !idTokenExpires) {
      return
    }
    const expireAt = dateFromUTCString(idTokenExpires)
    const id = store.getState().id
    if (id > 0) {
      clearInterval(id)
    }
    store.setState({
      id: setInterval(() => {
        store.setState({
          exp: remaining(expireAt)
        })
      }, interval) as unknown as number,
      exp: remaining(expireAt),
      expireAt,
      idTokenExpires
    })
    return () => clearInterval(store.getState().id)
  }, [idTokenExpires, interval])
  return state
}
