import * as React from 'react'
import type { ReactNode } from 'react'
import useState from './state'
import { shallow } from 'zustand/shallow'
import { Icon } from '@drdropin-tech/theseus'

interface Props {
  filter?: string | string[]
  children?: ReactNode
}

export function Warning(this: string, props: Props) {
  const state = useState((state) => state.instances[this], shallow)
  const [browser, os] = useState((state) => [state.browser, state.os], shallow)
  const filter = [props.filter ?? ''].flat().filter(Boolean)

  let unsupported = Object.entries(state ?? {})
    .filter(([, hasSupport]) => !hasSupport)
    .map(([prop]) => prop)

  // If only want to render based on check for spesific subset of properties
  unsupported = unsupported.filter((prop) => filter.includes(prop))
  if (!unsupported.length) return null

  return (
    <div className="w-full border-2 border-warning rounded-md p-2 flex flex-row gap-2">
      <div>
        <Icon name="AlertCircle" className="stroke-warning" />
      </div>

      <div>
        {props.children}
        <div className="mt-2 text-grey text-xs">
          {browser}, {os} ({unsupported.join(', ')})
        </div>
      </div>
    </div>
  )
}
