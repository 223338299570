import * as React from 'react'
import * as stores from '@stores'

export default function DeviceListener() {
  const handleDeviceChange = React.useCallback(async () => {
    const devices = await navigator.mediaDevices.enumerateDevices()
    stores.useDevices.getState().setDevices(devices)

    const sorted = stores.useDevices.getState().derived.sorted
    const { setDevice, ...usedDevices } = stores.useSettings.getState()

    /*
      If newly retrieved devices does not list previously used device, it
      will default to the first available device of its kind
    */
    for (const kind of ['videoinput', 'audioinput', 'audiooutput'] as const) {
      const availableDevices = sorted[kind].map((e) => e.deviceId)
      const usedDevice = usedDevices[kind].id ?? ''

      if (!availableDevices.includes(usedDevice))
        setDevice(kind, availableDevices[0])
    }
  }, [])

  React.useEffect(() => {
    navigator.mediaDevices.addEventListener('devicechange', handleDeviceChange)

    return () => {
      navigator.mediaDevices.removeEventListener(
        'devicechange',
        handleDeviceChange,
      )
    }
  }, [handleDeviceChange])

  return null
}
