import Pusher from 'pusher-js'

export enum ConnectionStates {
  Connecting = 'connecting',
  Connected = 'connected',
  Disconnected = 'disconnected',
}

export interface UseSocketsOptions {
  url?: string
  onConnect?: () => void
  onDisconnect?: () => void
  autoConnect: boolean
  verbose?: boolean
}

export interface UseSocketChannelOptions {
  socket?: Pusher
  channelName?: string
  events?: SocketEvent[]
  verbose?: boolean
}

export interface UseSocket extends UseSocketsOptions, UseSocketChannelOptions {}

export interface SocketEvent {
  name: string
  target?: string
  handler?: (payload: any) => void
}
export interface Payload extends Record<string, any> {
  id: string
}
