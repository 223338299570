import {
  Dropdown,
  Icon,
  Menu,
  MenuCategory,
  MenuItem,
} from '@drdropin-tech/theseus'
import { useTranslation } from '@hooks'
import { languages, Language } from '@lib/types'
import { useRouter } from 'next/router'
import * as React from 'react'

const LocaleMenu = React.memo(function LocaleMenu() {
  const { translations } = useTranslation()
  const router = useRouter()
  const updateLocale = React.useCallback(
    (nextLocale: Language) => {
      const { pathname, asPath, query } = router
      // change just the locale and maintain all other route information including href's query
      router.push({ pathname, query }, asPath, { locale: nextLocale })
    },
    [router],
  )

  return (
    <Dropdown
      trigger={
        <span>
          <Icon name="Globe" width={24} height={24} />
        </span>
      }
      contentClassName="right-0"
      triggerClassName=""
    >
      <Menu className="bg-white min-w-max right-0 mt-2 shadow-md transition-opacity rounded-md">
        <MenuCategory title={translations.locale.language} />
        {languages.map((key) => (
          <MenuItem key={key}>
            <button onClick={() => updateLocale(key)}>
              {translations.locale[key]}
            </button>
          </MenuItem>
        ))}
      </Menu>
    </Dropdown>
  )
})

export default LocaleMenu
