// Resources for regular expressions:
// github.com/DamonOehlman/detect-browser/blob/master/src/index.ts
// https://github.com/faisalman/ua-parser-js/blob/master/src/ua-parser.js

type Rule = [string, RegExp]

const browsers: Rule[] = [
  ['edge', /Edge\/([0-9\._]+)/],
  ['edge-ios', /EdgiOS\/([0-9\._]+)/],
  ['samsung', /SamsungBrowser\/([0-9\.]+)/],
  ['edge-chromium', /EdgA?\/([0-9\.]+)/],
  ['chrome', /(?!Chrom.*OPR)Chrom(?:e|ium)\/([0-9\.]+)(:?\s|$)/],
  ['firefox', /Firefox\/([0-9\.]+)(?:\s|$)/],
  ['fxios', /FxiOS\/([0-9\.]+)/],
  ['android', /Android\s([0-9\.]+)/],
  ['ios', /Version\/([0-9\._]+).*Mobile.*Safari.*/],
  ['safari', /Version\/([0-9\._]+).*Safari/],
  ['ios-webview', /AppleWebKit\/([0-9\.]+).*Mobile/],
  ['ios-webview', /AppleWebKit\/([0-9\.]+).*Gecko\)$/],
]

const operatingSystems: Rule[] = [
  ['macOS', /(Mac_PowerPC)|(Macintosh)/],
  ['iOS', /iP(hone|od|ad)/],
  ['Android OS', /Android/],
  ['Windows Mobile', /IEMobile/],
  ['Windows', /Windows/],
  ['Chrome OS', /CrOS/],
  ['Linux', /(Linux)|(X11)/],
]

export default function browserCheck() {
  const ua = navigator?.userAgent || ''
  const check = (rules: Rule[]) => (rules.find((r) => r[1].test(ua)) || [])[0]

  return {
    browser: check(browsers) ?? '',
    os: check(operatingSystems) ?? '',
  }
}
