import * as React from 'react'
import useState from './state'
import { Warning } from './Warning'

/*
  Hook returning stateful data w/ Component utilizng said
  data to render a warning.

  Expects a function returning an Object with the key to check for in an
  interface. e.g. { sinkId: document.createElement('audio') }
  Wrapping obj in callback is to avoid ssr-errors when checking
  on dom interfaces like document/window etc…

  The reason the above is preferred over
  document.createElement('audio')?.sinkId is because many experimental/
  poorly supported webstandards don't ship w/ typescript types. And thus
  you don't have to cast to a patched interface first. E.g (src/lib/types/dom.ts)
  Also makes it easier to warn about missing support.

  Is very useful when you need not only a component warning users, but
  state variables returned making it easy to control and disable functionality.
  E.g disable select field for Speaker selection: src/pages/settings/index.tsx
*/

export default function useIsSupported<T extends Record<string, any>>(
  checks: () => T,
) {
  type Support = { [Property in keyof T]: boolean }

  const id = React.useId()
  const supported = (useState((state) => state.instances[id]) ?? {}) as Support
  const [browser, os] = useState((state) => [state.browser, state.os])

  React.useEffect(() => {
    const support = Object.entries(checks()).reduce<Support>(
      (acc, [prop, struct]) => {
        try {
          acc[prop as keyof T] = prop in struct
        } catch (e) {
          acc[prop as keyof T] = false
        }

        return acc
      },
      checks(),
    )

    useState.getState().setInstanceState(id, support)
  }, [checks, id])

  React.useEffect(() => {
    return () => useState.getState().deleteInstance(id)
  }, [id])

  return {
    Warning: Warning.bind(id),
    supported,
    browser,
    os,
  }
}
