import { create } from 'zustand'
import { isEqualShallow } from '@utils'

interface State {
  isOpen: boolean
  mount: string
}

interface Store {
  instances: Record<string, State | undefined>
  setInstanceState: (id: string, payload: State) => void
  deleteInstance: (id: string) => void
}

const useState = create<Store>((set, get) => ({
  instances: {},

  setInstanceState(id, payload) {
    const current = get().instances[id] ?? {}

    if (isEqualShallow(payload, current)) return

    const update = { instances: { [id]: payload } }

    set(update)
  },

  deleteInstance(id) {
    const instances = get().instances
    const instance = instances[id]

    if (instance) delete instances[id]
  },
}))

export default useState
