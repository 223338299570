import { useTranslation } from '@hooks'
import { FallbackProps } from 'react-error-boundary'
import * as Sentry from '@sentry/nextjs'
import { logsink } from '@lib/logsink'

function ErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
  const { translations } = useTranslation()
  Sentry.captureException(error)
  logsink.error({ error }, 'ErrorFallback')
  return (
    <div role="alert">
      <p>{translations.error.header}</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>{translations.error.retry}</button>
    </div>
  )
}

export default ErrorFallback
