/**
 * Scrub an object of sensitive data
 * @param replacement
 * @param propertyNames
 * @returns object
 */
export const Scrub = (replacement: string, propertyNames: string[]) => (obj: any): any => {

  function isObject<T>(obj: T) {
    return Object.prototype.toString.call(obj) === '[object Object]';
  }

  function isNullish<T>(obj: T) {
    return obj === null || obj === undefined;
  }

  function hasProp<T>(obj: T, prop: string) {
    return Object.prototype.hasOwnProperty.call(obj, prop);
  }

  function shouldScrub(propertyPath: string, currentProp: string) {
    return propertyNames.includes(propertyPath) || propertyNames.includes(currentProp);
  }

  function scrub<T>(obj: T, propertyPath: string[]): Record<string,any> {
    const createSlate = () => {
      if (Array.isArray(obj)) {
        return [] as Record<string, any>;
      }
      return {} as Record<string, any>;
    }
    const scrubbed = createSlate();
    for (const prop in obj) {
      if (hasProp(obj, prop)) {
        const currentPath = [...propertyPath, prop];
        if (shouldScrub(currentPath.join('.'), prop)) {
          isNullish(obj[prop]) ? scrubbed[prop] = obj[prop] :
            scrubbed[prop] = replacement;
          continue;
        }
        if (isObject(obj[prop]) || Array.isArray(obj[prop]) && obj[prop] !== null) {
          scrubbed[prop] = scrub(obj[prop], currentPath);
        } else {
          scrubbed[prop] = obj[prop];
        }
      }
    }
    return scrubbed;
  }

  return scrub(obj, []);
}
