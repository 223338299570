import { UseSocketChannelOptions, UseSocketsOptions } from '@lib/types/sockets'
import useSocketChannel from './useSocketChannel'
import useSocketService from './useSocketService'

const useSocket = (
  props: Omit<UseSocketChannelOptions & UseSocketsOptions, 'socket'>,
) => {
  // connect to socket server
  const { socket, state } = useSocketService(props)

  // listen to socket channel with events
  const channel = useSocketChannel({ ...props, socket })

  return { socket, channel, state }
}

export default useSocket
