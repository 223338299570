import { useContext } from 'react'
import Context from './Context'
import { DrDropinLogo, DrDropinLogoSymbol } from '@components/Logos'
import LocaleMenu from '../Locale/LocaleMenu'
import { cn } from '@utils/css'

export default function Header() {
  const { isNarrow } = useContext(Context)

  return (
    <div
      className={cn(
        'flex justify-center py-4',
        isNarrow ? 'mx-auto w-10' : 'mx-1 px-4',
      )}
    >
      {isNarrow ? (
        <DrDropinLogoSymbol />
      ) : (
        <div className="flex items-end justify-between w-full">
          <DrDropinLogo />
          <LocaleMenu />
        </div>
      )}
    </div>
  )
}

/*
  TODO: Language Popup-menu above is not rendered through portal, thus being bound to a stacking
  context and ultimately cropped when the sidebar shrinks. This should be adressed
  in theseus. Temp solution is to hide menu. Also do not have any design for it
  in narrow mode currently
*/
