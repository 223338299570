import {
  Dropdown,
  DropdownProps,
  Icon,
  Menu,
  MenuCategory,
  MenuItem,
} from '@drdropin-tech/theseus'
import { useTranslation } from '@hooks'
import { logOut } from '@utils/auth'
import type { ReactNode } from 'react'

interface Props {
  userName: string
  position?: DropdownProps['position']
  children: ReactNode
}

const UserMenu = (props: Props) => {
  const { translations } = useTranslation()
  return (
    <Dropdown
      trigger={props.children}
      position={props.position || 'bottom'}
      contentClassName="right-0"
      triggerClassName="cursor-pointer"
    >
      <Menu className="bg-white min-w-max right-0 mt-2 shadow-md transition-opacity rounded-md">
        <MenuCategory title={props.userName} />
        <MenuItem>
          <button
            onClick={() => logOut()}
            className="appearance-none flex px-4 py-2 text-sm text-gray-700"
          >
            <Icon name="LogOut" iconSize="xs" />
            {translations.auth.signOut}
          </button>
        </MenuItem>
      </Menu>
    </Dropdown>
  )
}

export default UserMenu
