import * as stores from '@stores'
import * as userMedia from '@utils/userMedia'
import type { CoercedConstraints } from './types'

export default async function get(constraints: MediaStreamConstraints) {
  // Safeguards <undefined | {} | Partial<Record<"video" | "audio", false>>> from being
  // passed to native getUserMedia which would otherwise prevent promise from resolving
  if (!constraints.audio && !constraints.video) return

  const stream = await navigator.mediaDevices.getUserMedia(constraints)
  const devices = await navigator.mediaDevices.enumerateDevices()

  // Enumarete devices only yields proper device data after
  // getUserMedia call to avoid fingerprinting
  stores.useDevices.getState().setDevices(devices)
  const sorted = stores.useDevices.getState().derived.sorted

  const overrides: CoercedConstraints = {}

  for (const [kind, track] of userMedia.getTracks(stream, true)) {
    if (!track) continue

    /*
      A call to getUserMedia will return a maximum of two tracks;
      audio and video. This sets the utilized device as
      the preferred device in the settings store and deals with
      chrome's "default" duplicates

      https://stackoverflow.com/a/33770656/5754578      
    */

    if (track.getSettings().deviceId === 'default')
      overrides[kind === 'audioinput' ? 'audio' : 'video'] = {
        ...track.getConstraints(),
        deviceId: sorted[kind][0]?.deviceId,
      }
  }

  await userMedia.replace(stream, overrides)

  // Set device settings store based on devices utlized by either
  // stream track or nullish assign first avaialable device of its kind
  for (const [kind, track] of userMedia.getTracks(stream, true)) {
    const availableDevices = sorted[kind].map((e) => e.deviceId)
    const usedDevice = stores.useSettings.getState().audiooutput.id

    if (track) {
      stores.useSettings
        .getState()
        .setDevice(kind, track?.getSettings().deviceId)
    } else if (!availableDevices.includes(usedDevice ?? '')) {
      stores.useSettings.getState().setDevice(kind, availableDevices[0])
    }
  }

  // Setting device store's audiooutput needs separate
  // handling as it is not a part of MediaStreams
  // (But devices are only retrieved after initiating a stream, oddly enough)
  const availableAudiooutputs = sorted.audiooutput.map((e) => e.deviceId)
  const usedDevice = stores.useSettings.getState().audiooutput.id

  if (!availableAudiooutputs.includes(usedDevice ?? ''))
    stores.useSettings
      .getState()
      .setDevice('audiooutput', availableAudiooutputs[0])

  return stream
}

// NOTE: Even with permissions from previous session, firefox will not
// return 'audiooutput' devices when enumarateDevices is called before getUserMedia.
// For this reason the default for 'audiooutput' needs to set here.
// instead of the previous approach of setting it in @components/settings/DeviceListener
// on Mount. The previous solution cleared audioOutput from localStorage
// because the devices weren't available before first getUserMedia call
