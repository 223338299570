import config from '@lib/config'
import type { AxiosInstance, AxiosResponse } from 'axios';
import axios, { AxiosError } from 'axios';
import * as Sentry from '@sentry/nextjs'

type ApiInstanceProps = {
  host?: string
  jwt?: string
  headers?: Record<string, string>
}

// Cache initialized instances per host
const instances: Record<string, AxiosInstance> = {}

export const getInstance = ({
  host = config.coreApi,
  jwt,
  headers = {},
}: ApiInstanceProps) => {
  if (!instances[host]) {
    const instance = axios.create({
      baseURL: host,
      headers,
    })

    // Send all errors to Sentry
    instance.interceptors.response.use(
      (response: AxiosResponse) => response,
      (error: AxiosError) => {
        Sentry.captureException(error);
        return Promise.reject(error);
      },
    );

    instances[host] = instance
  }
  if (jwt && jwt.length > 0) {
    instances[host].defaults.headers.common['Authorization'] = `Bearer ${jwt}`
  }
  return instances[host]
}
