import * as React from 'react'

export default function useUnMount(fn: () => void) {
  const ref = React.useRef(fn)
  ref.current = fn

  React.useEffect(() => {
    return () => {
      ref.current()
    }
  }, [])
}

/*
  When using props or state variables inside
  a useEffect which should only trigger on unMount,
  exhaustive-deps will complain. including the deps
  negates its function as a lifecycle hook.

  You can either disable the rule (ugly) or
  put whatever variables you need in a ref (uglier)

  This hook resolves above issues and has a cleaner
  signature than useEffect
*/
