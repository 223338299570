import * as userMedia from '@utils/userMedia'
import type { CoercedConstraints } from '@utils/userMedia/types'

export default async function update(
  stream: MediaStream,
  constraints: CoercedConstraints,
) {
  for (const [kind, track] of userMedia.getTracks(stream)) {
    if (!track || !constraints[kind]) continue

    await track.applyConstraints(constraints[kind])

    /*
      getConstraints returns previously passed arguments to
      getUserMedia/applyConstraints and may return
      constraints that were never satisfied.

      use getSettings intead to assess in effect constraints
      https://developer.mozilla.org/en-US/docs/Web/API/MediaStreamTrack/getConstraints
    */

    // console.log(
    //   `Current in effect constraints for ${kind}-track:`,
    //   track.getSettings(),
    // )
  }
}
