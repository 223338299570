type Track = MediaStreamTrack | undefined
type Stream = MediaStream | undefined

/*
  Function is intended for use of getUserMedia with the default
  maximum of two tracks; audio and video.

  Returns const array which is easy to interate w/ key & data.
  Is overloaded because webrtc device-methods tend to use
  e.g. "videoinput" indicating type, whilst a track is just "video"
*/

function getTracks(
  stream: Stream,
  deviceKindKey?: false,
): [['video', Track], ['audio', Track]]

function getTracks(
  stream: Stream,
  deviceKindKey?: true,
): [['videoinput', Track], ['audioinput', Track]]

function getTracks(stream: Stream, deviceKindKey = false) {
  return [
    [deviceKindKey ? 'videoinput' : 'video', stream?.getVideoTracks()[0]],
    [deviceKindKey ? 'audioinput' : 'audio', stream?.getAudioTracks()[0]],
  ] as const
}

export default getTracks

export function getTracksv2(stream: Stream) {
  const map = new Map<'video' | 'audio', Track>()
  const audio = stream?.getAudioTracks()[0]
  const video = stream?.getVideoTracks()[0]

  if (video) map.set('video', video)
  if (audio) map.set('audio', audio)

  return map
}
