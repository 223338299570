import AppContent from '@components/Layout/AppContent'
import useTheme from '@hooks/useTheme'
import { CustomAppProps } from '@lib/types'
import '@styles/tailwind.css'
import { isDev } from '@utils/common'
import getVertical from '@utils/getVertical'
import type { Session } from 'next-auth'
import { SessionProvider } from 'next-auth/react'
import { PostHogProvider } from 'posthog-js/react'
import Head from 'next/head'
import { useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { QueryClient, QueryClientProvider } from 'react-query'
import config from '../lib/config'
import * as Sentry from '@sentry/nextjs'
import { logsink } from '@lib/logsink'
import posthog from '@lib/posthog'
export const siteTitle = 'Dr.Dropin Yoga'

function App({
  Component,
  pageProps: { session, ...pageProps },
  router,
}: CustomAppProps<{ session: Session }>) {
  const [queryClient] = useState(() => new QueryClient())
  const currentVertical = getVertical(router.pathname)
  useTheme(currentVertical)

  return (
    <>
      <Head>
        <title>{siteTitle}</title>
        <meta name="description" content={siteTitle} />
        <link rel="icon" href="/favicon.ico" />
        <link rel="icon" href="/favicon.ico" />
        <meta name="og:title" content={siteTitle} />
        <meta name="twitter:card" content="summary_large_image" />
      </Head>
      <ErrorBoundary
        FallbackComponent={({ error }) => {
          Sentry.captureException(error)
          logsink.error(error, error.message)
          return (
            <div role="alert">
              <pre>{error.message}</pre>
            </div>
          )
        }}
      >
        <PostHogProvider client={posthog}>
          <SessionProvider
            session={session}
            refetchInterval={config.refreshTokenInterval} // in sec
            refetchOnWindowFocus={true}
          >
            {isDev && (
              <div className="fixed top-2 -right-10 w-32 h-6 bg-orange rotate-45 text-center z-[10000] pointer-events-none">
                DEV
              </div>
            )}
            <QueryClientProvider client={queryClient}>
              <AppContent
                Component={Component}
                router={router}
                pageProps={pageProps}
              />
            </QueryClientProvider>
          </SessionProvider>
        </PostHogProvider>
      </ErrorBoundary>
    </>
  )
}

export default App
