import type { SetStateAction } from 'react'
import * as React from 'react'

// Typescripted version of https://ysfaran.github.io/blog/post/0002-use-state-with-promise/
// Used just as useState except it returns a promise resolving
// with the newly set state when component has rerendered

export default function useStatePromise<T>(initialState: T | (() => T)) {
  const [state, setState] = React.useState<T>(initialState)

  type Resolver = (state: T | PromiseLike<T>) => void
  const resolverRef = React.useRef<null | Resolver>()

  React.useEffect(() => {
    if (resolverRef.current) {
      resolverRef?.current(state)
      resolverRef.current = null
    }
  }, [state])

  function handleSetState(stateAction: SetStateAction<T>) {
    setState(stateAction)
    return new Promise<T>((resolve) => {
      resolverRef.current = resolve
    })
  }

  return [state, handleSetState] as const
}
