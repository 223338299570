import useTranslation from '@hooks/useTranslation'

const InsufficientPermissions = () => {
  const { translations } = useTranslation()
  return (
    <div className="flex justify-center">
      <span className="bg-error text-white rounded-md p-2">
        {translations.auth.insufficientPermissions}
      </span>
    </div>
  )
}

export default InsufficientPermissions
