export default function isEqualShallow<T extends Record<string, any>>(
  cmp1: T,
  cmp2: T,
) {
  const arr1 = Object.entries(cmp1)
  const arr2 = Object.entries(cmp2)

  if (arr1.length !== arr2.length) return false

  for (const [key, val] of arr1) {
    const equal = (c: [string, unknown]) => key === c[0] && val === c[1]

    if (!arr2.find(equal)) return false
  }

  return true
}
