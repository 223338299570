import { useState } from 'react'
import type { ReactNode } from 'react'
import { useIntersectionObserver } from '@hooks'
import { cn } from '@utils/css'

interface Props {
  children: ReactNode
  className?: string
  stickyClassName?: string
}

export default function Sticky(props: Props) {
  const [isSticky, setIsSticky] = useState(false)
  const [, setStickyRef] = useIntersectionObserver(
    (e) => setIsSticky(e.boundingClientRect.top < 0),
    { threshold: 1 },
  )

  return (
    <>
      <div className="h-px" />
      <div
        ref={setStickyRef}
        className={cn(
          'sticky -top-px',
          props.className,
          isSticky && props.stickyClassName,
        )}
      >
        {props.children}
      </div>
    </>
  )
}
