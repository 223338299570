import { UserRoles } from '@lib/types/auth'
import { User } from 'next-auth'
import { useUser } from './useSession'

// roles for which you book appointments at a specific practitioner, eg. I want to talk to Psychologist Gertrud
const privateSpecialistRoles = [UserRoles.Psychologist, UserRoles.Physio]
// roles where you don't care which practitioner you talk with, eg. I want to talk to some dermatologist
const commonSpecialistRoles = [UserRoles.Dermatologist]

const getPostfixForSocketChannel = (user: User) => {
  const { roles = [] } = user

  const hasPrivateSpecialistRole = roles.some((role) =>
    privateSpecialistRoles.includes(role),
  )

  let postfix = ''

  if (hasPrivateSpecialistRole) {
    postfix = `-${user.userId}`
  } else {
    // will only find the first match from commonSpecialistRoles,
    // but that should be ok considering a professional usually only has one role
    const commonSpecialistRole = commonSpecialistRoles.find((role) =>
      roles.includes(role),
    )
    if (commonSpecialistRole) {
      postfix = `-${commonSpecialistRole}`
    }
  }
  return postfix
}

const useSocketConsultationChannelName = () => {
  const user = useUser()
  const consultationChannel = `private-appointments`

  if (!user) return consultationChannel

  let postfix = getPostfixForSocketChannel(user)

  return consultationChannel + postfix
}

export default useSocketConsultationChannelName
