import { usePersistent, useMount } from '@hooks'
import * as stores from '@stores'
import * as userMedia from '@utils/userMedia'
import type { Constraints } from '@utils/userMedia/types'
import * as React from 'react'
import { shallow } from 'zustand/shallow'

/*
  Designed to utilizie a single stream throughout the entire hook's lifespan.
  Only acts upon single audio and video track in a persistent stream.

  Note: Interacts with zustand-stores; thus instancing two
  hooks simultaneously will only utilize/manipulate same device of kind.
  DeviceId is omitted from signature because this is handled
  through global store. Function signature is otherwise indetical to
  native getUserMedia

  Returns both steam and tracks even though the latter can be retrieved
  through the stream. This is to make hook able to return tracks as
  reactive state variables
*/

export default function useUserMedia(constraints: Constraints) {
  const [error, setError] = React.useState<Error>()
  const [stream, setStream] = React.useState<MediaStream>()
  const [audioTrack, setAudioTrack] = React.useState<MediaStreamTrack>()
  const [videoTrack, setVideoTrack] = React.useState<MediaStreamTrack>()

  const streamRef = React.useRef(stream)
  streamRef.current = stream

  const devices = stores.useSettings(
    (state) => ({ video: state.videoinput.id, audio: state.audioinput.id }),
    shallow,
  )

  const coercedConstraints = usePersistent(
    userMedia.coerceConstraints(devices, constraints),
  )

  React.useEffect(() => {
    if (!streamRef.current) return

    userMedia
      .updateTrackset(streamRef.current, coercedConstraints)
      .then(updateTracks)
      .catch(setError)

    function updateTracks() {
      setVideoTrack(streamRef.current?.getVideoTracks()[0])
      setAudioTrack(streamRef.current?.getAudioTracks()[0])
    }
  }, [coercedConstraints])

  useMount(async () => {
    const initial = await userMedia.get(coercedConstraints).catch((e) => e)

    if (initial instanceof Error) {
      setError(initial)
      return
    }

    if (initial) {
      setStream(initial)
      setVideoTrack(initial.getVideoTracks()[0])
      setAudioTrack(initial.getAudioTracks()[0])
    }
  })

  React.useEffect(() => () => userMedia.cleanup(stream), [stream])

  return {
    stream,
    error,

    audioTrack,
    videoTrack,

    enable: userMedia.enable.bind(stream),
    // Todo: return more stream data. (loading, muted, etc…)
  }
}
