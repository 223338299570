import AuthWrapper from '@components/Auth/AuthWrapper'
import ErrorFallback from '@components/Error/ErrorFallback'
import Layout from '@components/Layout'
import DeviceListener from '@components/Settings/DeviceListener'
import { user } from '@drdropin-tech/minotaur'
import useSockets from '@hooks/useSockets'
import { CustomAppProps } from '@lib/types'
import { SessionStatus } from '@lib/types/auth'
import { isDev, isTest } from '@utils/common'
import { useSession } from 'next-auth/react'
import { useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import * as Sentry from '@sentry/nextjs'
import { usePostHog } from 'posthog-js/react'
import { logsink } from '@lib/logsink'
import DetectIdleTime from '@components/Auth/DetectIdleTime'

function AppContent({ Component, pageProps, router }: CustomAppProps<{}>) {
  const { data: session, status } = useSession()
  const sockets = useSockets({
    verbose: !isTest,
  })
  const posthog = usePostHog()

  useEffect(() => {
    posthog?.capture('SessionStatus', {
      status,
      user: session?.user,
      error: session?.error,
    })
  }, [status, session, posthog])

  useEffect(() => {

    if (isTest || isDev) return

    // set / unset identity for posthog
    if (status === SessionStatus.Authenticated && session?.user.userId) {
      try {
        const userName = session.user.name || user.getFullName(session.user)
        posthog?.identify(`${userName} - ${session?.user.userId}`, {
          name: userName,
        })
      } catch (e: unknown) {
        const err = e as Error
        Sentry.captureException(err)
        logsink.error({
          error: err.message
        }, 'Posthog identify failed')
      }
    } else if (status === SessionStatus.Unauthenticated) {
      try {
        posthog?.reset()
      } catch (err: unknown) {}
    }
  }, [status, session, posthog])

  return (
    <Layout pathname={router.pathname}>
      {sockets}
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => {
          // reset the state of your app so the error doesn't happen again
          logsink.info({}, 'TODO: reset state')
        }}
      >
        <AuthWrapper Component={Component} pageProps={pageProps} />
        <DeviceListener />
        <DetectIdleTime />
      </ErrorBoundary>
    </Layout>
  )
}

export default AppContent
