export enum VippsPaymentStatus {
  initiated = 'initiated', // has initiated vipps payment without phone number
  reserved = 'reserved', // payment has been reserved - waiting for approval / refusal
  cancelled = 'cancelled', // aborted by user
  captured = 'captured', // paid in vipps
  rejected = 'rejected', // rejected payment
  refunded = 'refunded', // refunded payment
}

export enum PrescriptionStatus {
  initiated = 'initiated', // has completed order - not completed payment in vipps
  pending = 'pending', // paid in vipps - waiting for approval / refusal
  approved = 'approved', // approved by doctor
  refused = 'refused', // refused by doctor
  closed = 'closed', // aborted by anonymous user or cancelled by user in vipps app
}

/*
"identity": {
    "nin": "18018340336",
    "sid": "53da83e83da308b4",
    "sub": "bc1d3b03-6f68-40d2-914e-c4f579080879",
    "name": "Test User",
    "email": "test@vipps.no",
    "address": {
      "region": "OSLO",
      "country": "NO",
      "formatted": "BOKS 6300, ETTERSTAD\n0603\nOSLO\nNO",
      "postal_code": "0603",
      "address_type": "home",
      "street_address": "BOKS 6300, ETTERSTAD"
    },
    "birthdate": "1955-01-18",
    "given_name": "Test",
    "family_name": "User",
    "phone_number": "4799374683",
    "email_verified": false,
    "other_addresses": []
  }
*/
export interface IPrescriptionUserAddress {
  region: string
  country: string
  formatted: string
  postal_code: string
  address_type: string
  street_address: string
}
export interface IPrescriptionUser {
  nin: string
  sid: string
  sub: string
  name: string
  email?: string
  address?: IPrescriptionUserAddress
  birthdate: string
  given_name: string
  family_name: string
  phone_number: string
  email_verified: boolean
  other_addresses: IPrescriptionUserAddress[]
}

export type StepChoices =
  | string
  | { key: string; value: string; yellowFlag?: boolean }[]

export interface Step {
  step: string
  choices: StepChoices
}

export type Products =
  | Record<
      string,
      {
        category: string
        choice: string
      }
    >
  | undefined
export interface IPrescription {
  id: string // uuid
  createdAt: string // date string
  updatedAt: string // date string
  type: string
  status: PrescriptionStatus
  paymentStatus: VippsPaymentStatus
  data: {
    [key: string]: Step | any
    customerProductChoices: Products
    patientAge?: string
    patientBmi?: string
    version?: string
    language?: string
  }
  identity?: IPrescriptionUser
  professionalId: string | null
  shopifyId: string | null
  roles?: string[] 
}

export type TPrescriptions = IPrescription[]
