import * as userMedia from '@utils/userMedia'

/*
  getSettings() retrieves exact values utilizied in a track,
  which could come from constraianble properties in getConstraints()
  https://www.w3.org/TR/mediacapture-streams/#example-3

  Sometimes settings will deviate from constraints
  without resulting in an OverCosntrainedError.
  In these cases replacing a track is the best workaround
  
  Example: changing devices on the fly should work
  per the spec, and doesn't result in OverCosntrainedError. But even
  though applyConstraints resolves, it doesn't update device.

  Workaround is to replace the track with a new one
*/

export default async function replace(
  stream: MediaStream,
  constraints: Partial<Record<'video' | 'audio', MediaTrackConstraintSet>>,
) {
  const intermediateStream = await userMedia.get(constraints)

  if (!intermediateStream) return

  const intermediateTracks = {
    video: intermediateStream.getVideoTracks()[0],
    audio: intermediateStream.getAudioTracks()[0],
  }

  for (let [kind, track] of userMedia.getTracks(stream)) {
    if (!track || !constraints[kind]) continue

    if (intermediateTracks[kind]) {
      track.stop()
      stream.removeTrack(track)
      stream.addTrack(intermediateTracks[kind].clone())
    }
  }

  userMedia.cleanup(intermediateStream)
}
