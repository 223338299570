import { useRef, useEffect } from 'react'

export default function usePreviousRender<T extends unknown[]>(...incoming: T) {
  const ref = useRef<T | undefined[]>(Array(incoming.length).fill(undefined))

  useEffect(() => {
    ref.current = incoming
  })

  return ref.current
}
