import { Icon, Tooltip } from '@drdropin-tech/theseus'
import { IconName } from '@drdropin-tech/theseus/lib/components/Icon'
import { TooltipProps } from '@drdropin-tech/theseus/lib/components/Tooltip'
import useSocket from '@hooks/useSocket'
import requireAuth from '@lib/hoc/requireAuth'
import { UserRoles } from '@lib/types/auth'
import { ConnectionStates, SocketEvent } from '@lib/types/sockets'
import { isProd } from '@utils/common'

interface SocketListenerProps {
  url: string
  title?: string
  channelName?: string
  events: SocketEvent[]
  onConnect?: () => void
  onDisconnect?: () => void
  hidden?: boolean
  verbose?: boolean
}
type SocketListenerPropsWithTooltip = SocketListenerProps &
  Pick<TooltipProps, 'position'>

const iconStates: Record<ConnectionStates, IconName> = {
  [ConnectionStates.Connecting]: 'Activity',
  [ConnectionStates.Connected]: 'Zap',
  [ConnectionStates.Disconnected]: 'ZapOff',
}

const SocketListener = ({
  url,
  title,
  channelName,
  events,
  position,
  onConnect,
  onDisconnect,
  hidden = false,
  verbose = true,
}: SocketListenerPropsWithTooltip) => {
  // cache events to prevent rerendering

  // connect to socket
  const { state } = useSocket({
    url,
    channelName,
    autoConnect: true,
    events,
    onConnect,
    onDisconnect,
    verbose,
  })

  if (isProd || hidden) {
    return null
  }

  // display connection state
  return (
    <Tooltip title={title} position={position} appearance="accent">
      <Icon className="hidden" name={iconStates[state]} />
    </Tooltip>
  )
}

const SocketListenerWithRoleCheck = ({
  roles,
  ...props
}: SocketListenerPropsWithTooltip & { roles?: UserRoles[] }) => {
  const Component = requireAuth(SocketListener, roles)
  return <Component {...props} />
}

export default SocketListenerWithRoleCheck
