import { useSession } from 'next-auth/react'
import { useMemo } from 'react'

const useIsLoggedIn = () => {
  const { data: session } = useSession()
  const isLoggedIn = useMemo(() => !!session, [session])
  return isLoggedIn
}

export default useIsLoggedIn
