import resolveConfig from 'tailwindcss/resolveConfig'
import { theme } from '../../../tailwind.config'

const raw = resolveConfig(theme as any)

function getAllScreens() {
  const screens = raw.theme?.screens as Record<string, string>

  return Object.keys(screens).reduce<Record<string, number>>(
    (a, k) => ((a[k] = parseInt(screens[k])), a),
    {},
  )
}
function getState(kind: 'greater' | 'lesser') {
  const width = typeof window === 'undefined' ? 0 : innerWidth

  const cmp = (value: number) =>
    kind == 'greater' ? width >= value : width < value

  return Object.entries(getAllScreens()).reduce<Record<string, boolean>>(
    (a, [k, v]) => ((a[k] = cmp(v)), a),
    {},
  )
}

const breakpoints = {
  isGreater: () => getState('greater'),
  isLesser: () => getState('lesser'),
}
export default breakpoints
