import * as React from 'react'

export default function usePrevious<T extends any[]>(...incoming: T) {
  const state = React.useRef({ latest: incoming, previous: incoming })

  for (const [i, entry] of incoming.entries()) {
    const latest = state.current.latest[i]

    if (entry === latest) continue

    const swaps = [
      ['latest', entry],
      ['previous', latest],
    ] as const

    for (const [kind, swap] of swaps) {
      const tmp = [...state.current[kind]] as T
      tmp[i] = swap
      state.current[kind] = tmp
    }
  }

  return state.current.previous
}
