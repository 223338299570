import { logsink } from '@lib/logsink'
import { Payload, UseSocketChannelOptions } from '@lib/types/sockets'
import { Channel } from 'pusher-js'
import React, { useState } from 'react'
import { useQueryClient } from 'react-query'
import { usePostHog } from 'posthog-js/react'

const addHandler =
  (payload: Payload) =>
    (oldData: Payload | Payload[] = []) => {
      logsink.debug({ payload }, '[SocketChannel] Handling add')
      return Array.isArray(oldData)
        ? oldData
          .concat(payload)
          .filter((p, i, self) => self.findIndex((sp) => p.id === sp.id) === i) // filter unique
        : payload
    }
const updateHandler =
  (payload: Payload) =>
    (oldData: Payload | Payload[] = []) => {
      logsink.debug({ payload }, '[SocketChannel] Handling update')
      const update = (entity: Payload) => {
        return entity.id === payload.id ? { ...entity, ...payload } : entity
      }

      const isOldDataAnArray = Array.isArray(oldData)
      // add to list if not already in there
      if (isOldDataAnArray && !oldData.find((e) => e.id === payload.id)) {
        return oldData.concat(payload)
      }
      return isOldDataAnArray ? oldData.map(update) : update(oldData as Payload)
    }

const useSocketChannel = ({
  socket,
  channelName = 'private-appointments',
  events = [],
  verbose = true,
}: UseSocketChannelOptions) => {
  const queryClient = useQueryClient()
  const [channel, setChannel] = useState<Channel | null>(null)
  const posthog = usePostHog()

  React.useEffect(() => {
    // Return when no socket is available
    if (!socket) {
      return
    }
    logsink.debug({ channelName }, '[SocketChannel] Subscribing to channel')
    posthog?.capture('socket_channel_subscribed', { channelName })

    const channel = socket.subscribe(channelName)

    events.forEach((event) => {
      logsink.debug({ event }, '[SocketChannel] Binding event')
      posthog?.capture('socket_event_bound', { channelName, eventName: event.name })

      channel.bind(event.name, (payload: Payload) => {
        if (event.target) {
          // Handle add / update events
          if (event.name.toLowerCase().includes('added')) {
            queryClient.setQueriesData(event.target, addHandler(payload))
          } else if (event.name.toLowerCase().includes('update')) {
            queryClient.setQueriesData(event.target, updateHandler(payload))
          }
        }
        // execute provided event handler
        return event.handler?.(payload)
      })
    })

    setChannel(channel)

    return () => {
      logsink.debug({ channelName: channel.name }, '[SocketChannel] Unsubscribing from channel')
      posthog?.capture('socket_channel_unsubscribed', { channelName: channel.name })
      channel.unsubscribe()
    }
  }, [channelName, events, posthog, queryClient, socket, verbose])

  return channel
}

export default useSocketChannel
