import * as React from 'react'
import Breakpoints from './breakpoints'

/*
  Utilizes Tailwind breakpoints/screens to return
  an overview of all available breakpoints and stateful
  data about which breakpoint-tresholds are exceeded or not.
  Is render optimized and will only return a new
  object ref once the passing of a treshold changes
  Todo: debounce handleResize
*/

export default function useBreakpoints() {
  const [, setState] = React.useState('')

  React.useEffect(() => {
    function handleResize() {
      setState(JSON.stringify(Breakpoints.isGreater()))
    }

    addEventListener('resize', handleResize)
    return () => removeEventListener('resize', handleResize)
  }, [])

  return Breakpoints
}
