import * as React from 'react'
import { usePersistent } from '@hooks'

export default function useEventListener<K extends keyof WindowEventMap>(
  eventName: K,
  handler: (event: WindowEventMap[K]) => void,
  options: boolean | AddEventListenerOptions = {},
) {
  const savedHandler = React.useRef(handler)
  options = usePersistent(options)

  React.useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  React.useEffect(() => {
    const eventListener: typeof handler = (event) => savedHandler.current(event)

    addEventListener(eventName, eventListener, options)

    return () => removeEventListener(eventName, eventListener)
  }, [eventName, options])
}
