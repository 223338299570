import * as React from 'react'
import Portal from './Portal'
import useState from './state'
import useUnmount from '../useUnmount'

interface Options {
  defaultOpen?: boolean
}

export default function usePortal(mount: string, options: Options = {}) {
  const id = React.useId()
  const [isOpen, setIsOpen] = React.useState(!!options.defaultOpen)

  useState().setInstanceState(id, { mount, isOpen })
  useUnmount(() => useState.getState().deleteInstance(id))

  return {
    isOpen,

    togglePortal() {
      setIsOpen((prev) => !prev)
    },
    openPortal() {
      setIsOpen(true)
    },
    closePortal() {
      setIsOpen(false)
    },

    Portal: Portal.bind(id),
  }
}
