import { useRef } from 'react'

export default function usePreviousValue<T extends unknown[]>(...incoming: T) {
  const prev = useRef<T | undefined[]>(Array(incoming.length).fill(undefined))

  const curr = useRef(incoming)

  for (const [i, entry] of incoming.entries()) {
    const latest = curr.current[i]

    if (entry === latest) continue

    prev.current[i] = curr.current[i]
    curr.current[i] = entry
  }

  return prev.current
}
