import { useEffect, useRef } from 'react'
import type { ComponentProps } from 'react'
import * as Video from 'twilio-video'
import VideoFrame, { VideoFrameHandle } from '.'

interface Props extends ComponentProps<typeof VideoFrame> {
  tracks: (Video.VideoTrack | Video.AudioTrack)[]
  error?: Error
}

export default function TwilioVideoFrame({
  tracks,
  error,
  ...frameProps
}: Props) {
  const frameRef = useRef<VideoFrameHandle>(null)

  useEffect(() => {
    const elements = {
      video: frameRef.current?.videoEl,
      audio: frameRef.current?.audioEl,
    }

    for (const track of tracks) {
      const element = elements[track.kind]
      if (element) track.attach(element)
    }

    return () => {
      for (const track of tracks) {
        const element = elements[track.kind]

        if (isLocalTrack(track)) track.stop()
        if (element) track.detach(element)
      }
    }
  }, [tracks])

  return <VideoFrame error={error} ref={frameRef} {...frameProps} />
}

function isLocalTrack(
  track: Video.Track,
): track is Video.LocalAudioTrack | Video.LocalVideoTrack {
  const classes = [Video.LocalVideoTrack, Video.LocalAudioTrack]
  return classes.some((c) => track instanceof c)
}
