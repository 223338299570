import type { ReactNode } from 'react'

export enum SessionStatus {
  Authenticated = 'authenticated',
  Unauthenticated = 'unauthenticated',
  Loading = 'loading',
}

export enum UserRoles {
  /* not allowed */
  Anon = 'anon',
  Guest = 'guest',
  Customer = 'customer',
  TestPersonnel = 'testPersonnel',

  Professional = 'professional',
  Nurse = 'nurse',
  Psychologist = 'psychologist',
  Physio = 'fysio',
  Admin = 'admin',
  Dermatologist = 'dermatologist',
}

export const validRoles = [
  UserRoles.Admin,
  UserRoles.Professional,
  UserRoles.Nurse,
  UserRoles.Dermatologist,
  UserRoles.Physio,
  UserRoles.Psychologist,
]

export const getVideoRoles = () => {
  return validRoles
}
export const getPrescriptionRoles = () => {
  return [
    UserRoles.Admin,
    UserRoles.Professional,
    UserRoles.Nurse,
    UserRoles.Dermatologist,
  ]
}

export interface AuthProps {
  roles?: UserRoles[]
  unauthenticated?: ReactNode
  redirect?: string
  children?: ReactNode
}
