import * as React from 'react'
import * as stores from '@stores'
import * as Video from 'twilio-video'
import type { ComponentProps } from 'react'
import VideoFrame from '.'
import { TwilioVideoFrame } from './'
import { useMount } from '@hooks'

type Tracks = (Video.LocalVideoTrack | Video.LocalAudioTrack)[]
type Props = ComponentProps<typeof VideoFrame>

export default function TwilioLocalVideoFrame(props: Props) {
  const [videoDevice, audioDevice] = stores.useSettings((state) => [
    state.videoinput.id,
    state.audioinput.id,
  ])

  const [tracks, setTracks] = React.useState<Tracks>()
  const [error, setError] = React.useState<Error>()

  useMount(async () => {
    const tracks = await Video.createLocalTracks({
      video: { deviceId: videoDevice },
      audio: { deviceId: audioDevice },
    }).catch((e: Error) => e)

    if (tracks instanceof Error) {
      setError(tracks)
      return
    }

    setTracks(filterLocalTracks(tracks))
  })

  return <TwilioVideoFrame tracks={tracks ?? []} {...props} error={error} />
}

const filterLocalTracks = (tracks: Video.LocalTrack[]) =>
  tracks.filter(
    (track): track is Video.LocalAudioTrack | Video.LocalVideoTrack =>
      track.kind !== 'data',
  )
