import config from '@lib/config'

export const isClient = typeof window !== 'undefined'
export const isServer = typeof window === 'undefined'

export const isAnalyzing = process.env.ANALYZE === 'true'

export const isTest = config.environment === 'test'
export const isDev = config.environment === 'development'
export const isStaging = config.environment === 'staging'
export const isProd = config.environment === 'production'
