import * as userMedia from '@utils/userMedia'
import type { Kind, Actions, CoercedConstraints } from '@utils/userMedia/types'
import { kinds } from '@utils/userMedia/types'

/*
  Based on incoming constraints and in effect constraints,
  function will either update, replace or delete trakcs in a stream 
*/

export default async function updateTrackset(
  stream: MediaStream,
  constraints: CoercedConstraints,
) {
  let actions: Actions = {}

  for (const [kind, track] of userMedia.getTracks(stream)) {
    const prevDevice = track?.getSettings().deviceId
    const newDevice = constraints[kind]?.deviceId
    const deviceSwap = track && prevDevice !== newDevice

    if (!!constraints[kind] !== !!track) {
      actions[kind] = track ? 'remove' : 'add'
    } else {
      actions[kind] = deviceSwap ? 'replace' : 'update'
    }
  }

  const rm: Partial<Record<Kind, boolean>> = {}

  for (const kind of kinds) rm[kind] = actions[kind] == 'remove'
  userMedia.cleanup(stream, rm)

  const changes: Record<'add' | 'update' | 'replace', CoercedConstraints> = {
    add: {},
    update: {},
    replace: {},
  }

  for (const kind of kinds)
    for (const action of ['add', 'update', 'replace'] as const)
      if (actions[kind] == action) changes[action][kind] = constraints[kind]

  const intermediate = await userMedia.get(changes.add)

  for (const track of intermediate?.getTracks() ?? [])
    stream.addTrack(track.clone())

  userMedia.cleanup(intermediate)
  await userMedia.update(stream, changes.update)
  await userMedia.replace(stream, changes.replace)
}
