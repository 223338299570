import config from '@lib/config'
import { ConnectionStates, UseSocketsOptions } from '@lib/types/sockets'
import Pusher from 'pusher-js'
import { useEffect, useRef, useState } from 'react'
import * as Sentry from '@sentry/nextjs'
import { logsink } from '@lib/logsink'
import { usePostHog } from 'posthog-js/react'
import { useSession } from 'next-auth/react'

const useSocketService = ({
  url = config.coreApi,
  onConnect,
  onDisconnect,
  autoConnect = false,
}: UseSocketsOptions) => {

  const { data } = useSession()
  const websocket = useRef<Pusher>()
  const [state, setState] = useState<ConnectionStates>(
    ConnectionStates.Connecting,
  )
  const posthog = usePostHog()


  useEffect(() => {

    logsink.debug({ url }, '[SocketService] Initiating socket')
    posthog?.capture('socket_initiated', { url })

    websocket.current = new Pusher(config.socketAppKey, {
      cluster: 'eu',
      forceTLS: true,
      authEndpoint: `${url}/sockets/auth`,
      auth: {
        params: {},
        headers: {
          Authorization: `Bearer ${data?.idToken}`,
        },
      },
    })

    websocket.current.connection.bind('error', (err: unknown) => {
      Sentry.captureException(err)
      const error = err as Error
      logsink.error({ error, url }, '[Socket] Error')
      posthog?.capture('socket_error', { url })
    })

    websocket.current.connection.bind('disconnected', () => {
      logsink.debug({ url }, '[Socket] Disconnected')
      posthog?.capture('socket_disconnected', { url })
      setState(ConnectionStates.Disconnected)
      onDisconnect?.()
    })

    websocket.current.connection.bind('connected', () => {
      logsink.debug({ url }, '[Socket] Connected')
      posthog?.capture('socket_connected', { url })
      setState(ConnectionStates.Connected)
      onConnect?.()
    })

    if (autoConnect) {
      logsink.debug({ url }, '[Socket] Auto-connecting')
      posthog?.capture('socket_auto_connecting', { url })
      websocket.current.connect()
    }

    return () => {
      logsink.debug({ url }, '[Socket] Disconnecting')
      posthog?.capture('socket_disconnected', { url })
      if (websocket.current?.connection.state === 'connected') {
        websocket.current?.disconnect()
      }
    }

    // ignore idToken changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onConnect, onDisconnect, url, autoConnect])

  return { socket: websocket.current, state }
}

export default useSocketService
