export default function DrDropinLogo({
  className = '',
  styleText = { fill: 'var(--text-dark)' },
  styleIconTop = { fill: 'var(--text-dark)' },
  styleIconBottom = {
    fill: 'var(--bg-secondary)',
  },
}) {
  return (
    <svg
      width="123"
      height="46"
      style={styleText}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M107.175 0c-3.418 0-6.202 2.8-6.202 6.237V9.64h-3.42c-3.418 0-6.202 2.8-6.202 6.238a.95.95 0 0 0 .952.957.95.95 0 0 0 .951-.957c0-2.375 1.939-4.324 4.3-4.324h4.335a.95.95 0 0 0 .951-.957V6.237c0-2.374 1.939-4.323 4.3-4.323 2.362 0 4.3 1.949 4.3 4.323v4.36a.95.95 0 1 0 1.903 0v-4.36A6.177 6.177 0 0 0 107.175 0z"
        style={styleIconTop}
      />
      <path
        d="M122.048 14.955a.95.95 0 0 0-.951.957c0 2.375-1.939 4.324-4.3 4.324h-4.335a.95.95 0 0 0-.952.957v4.359c0 2.374-1.938 4.323-4.299 4.323-2.362 0-4.3-1.949-4.3-4.323v-4.36a.95.95 0 1 0-1.903 0v4.36c0 3.437 2.784 6.237 6.203 6.237 3.418 0 6.203-2.8 6.203-6.237v-3.367h3.383c3.419 0 6.203-2.8 6.203-6.237 0-.567-.423-.993-.952-.993z"
        style={styleIconBottom}
      />
      <path d="M4.3 26.012H.916a.936.936 0 0 0-.916.922v14.14c0 .496.423.922.916.922H4.23c5.075 0 8.106-2.977 8.106-7.939v-.177c.035-4.926-2.995-7.868-8.035-7.868zm-.106 1.879c4.828 0 5.85 3.26 5.85 5.953v.178c0 3.898-2.079 6.06-5.815 6.06H2.291V27.89h1.903z" />
      <path d="M19.525 30.123c-1.304.248-2.044.993-2.538 1.701v-.531c0-.603-.493-1.099-1.092-1.099-.6 0-1.093.496-1.093 1.099v9.64c0 .602.494 1.098 1.093 1.098s1.092-.496 1.092-1.099v-5.387c0-2.161.81-3.154 2.855-3.402.494-.07.881-.496.881-1.027 0-.32-.14-.603-.352-.78-.247-.177-.564-.284-.846-.213z" />
      <path d="M22.873 38.948c-.881 0-1.586.709-1.586 1.594 0 .886.705 1.595 1.586 1.595.881 0 1.586-.709 1.586-1.595s-.705-1.594-1.586-1.594z" />
      <path d="M31.825 26.012h-3.383a.936.936 0 0 0-.917.922v14.14c0 .496.423.922.917.922h3.313c5.075 0 8.106-2.977 8.106-7.939v-.177c0-4.926-2.996-7.868-8.036-7.868zm-.14 1.879c4.828 0 5.85 3.26 5.85 5.953v.178c0 3.898-2.08 6.06-5.816 6.06h-1.938V27.89h1.903z" />
      <path d="M47.015 30.123c-1.304.248-2.044.993-2.537 1.701v-.531c0-.603-.494-1.099-1.093-1.099-.6 0-1.093.496-1.093 1.099v9.64c0 .602.494 1.098 1.093 1.098.6 0 1.093-.496 1.093-1.099v-5.387c0-2.161.81-3.154 2.854-3.402.494-.07.881-.496.881-1.027 0-.32-.14-.603-.352-.78-.247-.213-.564-.284-.846-.213z" />
      <path d="M54.874 30.017c-3.348 0-5.78 2.516-5.78 6.025v.177c0 3.473 2.432 5.99 5.745 5.99 3.348 0 5.78-2.517 5.78-6.026v-.177c.035-3.473-2.397-5.989-5.745-5.989zm3.524 6.025v.142c0 2.516-1.374 4.146-3.56 4.146-2.184 0-3.559-1.595-3.559-4.146v-.178c0-2.551 1.375-4.146 3.56-4.146 2.185.035 3.56 1.666 3.56 4.182z" />
      <path d="M68.725 30.017c-1.515 0-2.89.815-3.665 1.737v-.461c0-.603-.494-1.099-1.093-1.099-.6 0-1.093.496-1.093 1.099V44.9c0 .603.494 1.1 1.093 1.1.6 0 1.093-.497 1.093-1.1v-4.359c.846 1.064 2.15 1.666 3.665 1.666 3.207 0 5.357-2.445 5.357-6.095v-.178c0-3.544-2.15-5.918-5.357-5.918zm3.101 6.025v.177c0 1.878-.563 4.146-3.313 4.146-2.29 0-3.56-1.453-3.56-4.146v-.177c0-2.587 1.34-4.147 3.56-4.147 2.01 0 3.313 1.63 3.313 4.147z" />
      <path d="M77.43 30.194c-.6 0-1.093.496-1.093 1.099v9.64c0 .602.494 1.098 1.093 1.098.6 0 1.092-.496 1.092-1.099v-9.64c0-.602-.493-1.098-1.092-1.098z" />
      <path d="M77.395 25.41c-.74 0-1.374.638-1.374 1.382 0 .744.634 1.382 1.374 1.382.74 0 1.374-.638 1.374-1.382 0-.744-.634-1.382-1.374-1.382z" />
      <path d="M87.263 30.017c-1.692 0-2.855.744-3.489 1.56v-.284c0-.603-.493-1.099-1.093-1.099-.599 0-1.092.496-1.092 1.099v9.64c0 .602.493 1.098 1.092 1.098.6 0 1.093-.496 1.093-1.099v-6.024c0-2.02 1.515-2.942 2.996-2.942 1.762 0 2.502.815 2.502 2.73v6.236c0 .603.493 1.099 1.093 1.099.599 0 1.092-.496 1.092-1.099v-6.13c0-3.97-2.29-4.785-4.194-4.785z" />
    </svg>
  )
}
