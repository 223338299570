import config from '@lib/config'
import { isDev } from '@utils/common'
import { getInstance } from 'api'
import { useRouter } from 'next/router'
import * as React from 'react'
import { useQuery } from 'react-query'
import * as languages from '../data/translations'

export default function useTranslation() {
  const router = useRouter()

  const locale = React.useMemo(
    () =>
      router.locales?.find((e) => e === router.locale) ??
      router.defaultLocale ??
      'no',
    [router.locale, router.defaultLocale, router.locales],
  )

  const {
    data: prescription,
    refetch,
    isLoading,
  } = useQuery<Record<string, any>, Error>(
    'prescription-translation',
    () => fetchPrescriptionTranslation(locale),
    {
      staleTime: Infinity,
      refetchOnMount: !isDev,
      refetchOnWindowFocus: !isDev,
    },
  )

  React.useEffect(() => {
    refetch()
  }, [locale, refetch])

  const translations = React.useMemo(
    () => languages[locale as keyof typeof languages],
    [locale],
  )

  const translate = React.useCallback(
    (term: string, context: Record<string, string> = {}) => {
      const data = { ...translations, prescription }
      const lookup = getKey(data, term)
      return interpolate(lookup ?? term, context) || `KEY:${term}`
    },
    [prescription, translations],
  )

  return {
    locale,

    /* Use for strongly typed local translations  */
    translations,

    /* Use for weakly typed fetched translations and/or interpolate values */
    translate,

    prescription,

    isLoading,
  }
}

export type Translations = ReturnType<typeof useTranslation>['translations']

function fetchPrescriptionTranslation(locale: string) {
  return getInstance({ host: config.prescriptionWeb })
    .get(`/api/translations/${locale}`)
    .then((res) => res.data)
}

function interpolate(text: string, values: Record<string, string> = {}) {
  for (const [key, val] of Object.entries(values))
    text = text.replaceAll(`\${${key}}`, val)

  return text
}

function getKey(obj: Record<string, any>, path: string) {
  const target = path.split('.').reduce<any>((acc, key) => acc?.[key], obj)
  return typeof target === 'string' ? target : undefined
}
