import { useContext } from 'react'
import Context from './Context'
import { Avatar, Icon } from '@drdropin-tech/theseus'
import { useSession } from 'next-auth/react'
import { SessionStatus } from '@lib/types/auth'
import UserMenu from '@components/Layout/UserMenu'
import { getFullName } from '@drdropin-tech/minotaur/user'
import { logOut } from '@utils/auth'

export default function Footer() {
  const { isNarrow } = useContext(Context)
  const { data: session, status } = useSession()
  const isAuthenticated = session && status == SessionStatus.Authenticated

  if (!isAuthenticated) return null

  return (
    <div className="bg-white border-t-2 border-black/10 sticky bottom-0 px-4 py-3">
      {!isNarrow && (
        <UserMenu userName={getFullName(session.user)} position="top">
          <div className={'flex gap-2 items-center'}>
            <Avatar
              avatarUrl={session?.user.image}
              avatarSize="sm"
              avatarShape="rounded-square"
            />
            <span>{getFullName(session.user)}</span>
          </div>
        </UserMenu>
      )}

      {/*
        TODO: Popup-menu above is not rendered through portal, thus being bound to a stacking
        context and ultimately cropped when the sidebar shrinks. This should be adressed
        in theseus. Temp solution is to render button instead of menu in narrow sidebar
      */}

      {isNarrow && (
        <button
          className={'text-moss-green flex gap-1 items-center'}
          onClick={() => logOut()}
        >
          <Avatar
            avatarUrl={session?.user.image}
            avatarSize="sm"
            avatarShape="rounded-square"
          />
          <Icon width={20} height={20} name={'LogOut'} />
        </button>
      )}
    </div>
  )
}
