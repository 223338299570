import { Spinner } from '@drdropin-tech/theseus'
import { useTranslation } from '@hooks'
import { AuthProps, SessionStatus } from '@lib/types/auth'
import { useSession } from 'next-auth/react'
import { useRouter } from 'next/router'
import InsufficientPermissions from './InsufficientPermissions'
import Login from './Login'

const Auth = ({
  children,
  unauthenticated,
  redirect,
  roles = [],
}: AuthProps) => {
  const { data: session, status } = useSession()
  const { translations } = useTranslation()
  const router = useRouter()

  if (status === 'loading') {
    return (
      <div className="flex items-center justify-center flex-grow">
        <Spinner lightBg />
      </div>
    )
  }

  // if we are authenticated and satisfy the (optionally) given roles
  if (status === SessionStatus.Authenticated) {
    if (
      roles.length === 0 ||
      (roles.length > 0 &&
        session?.user?.roles?.some((role) => roles.includes(role)))
    ) {
      return <>{children}</>
    } else if ((session?.user?.roles || []).length > 1) {
      // if the previous check failed and the user has some roles (customer role is default),
      // but none of them match the required roles specified
      return <InsufficientPermissions />
    }
  }

  // redirect to given url
  if (redirect) {
    router.push(redirect)
    return null
  }
  // return override
  if (unauthenticated) {
    return <>{unauthenticated}</>
  }
  // default unauthenticated message
  return (
    <div className="flex flex-col items-center">
      <div className="mb-2">{translations.auth.notLoggedIn}</div>
      <Login className="justify-center" />
    </div>
  )
}

export default Auth
