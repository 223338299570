import * as React from 'react'
import type { ReactNode, MouseEvent } from 'react'
import { copy } from '@utils/copy'
import { Icon } from '@drdropin-tech/theseus'
import { cn } from '@utils/css'

interface Props {
  children: ReactNode
  className?: string
  value?: string
  inline?: boolean
}

export default function Copy(props: Props) {
  const [isActive, setIsActive] = React.useState(false)
  const buttonRef = React.useRef<HTMLButtonElement>(null)

  function handleClick(e: MouseEvent<HTMLButtonElement>) {
    const text = buttonRef.current?.innerText ?? ''
    copy(props.value ?? text)
  }

  const bgClassName = cn(
    'absolute',
    props.inline
      ? '-inset-2 align-top'
      : '-left-2 -right-14 -top-2 -bottom-2 items-center',
  )

  return (
    <button
      ref={buttonRef}
      className={cn(
        'relative appearance-none',
        isActive && 'z-30',
        props.className,
      )}
      onMouseEnter={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
      onClick={handleClick}
    >
      {isActive && (
        <>
          <div
            className={cn(
              '-z-10 rounded-lg bg-[#dee2e2]', // TODO: Theseus colors
              bgClassName,
            )}
          />
          <div className={cn('z-40 flex justify-end', bgClassName)}>
            <Icon
              name="Copy"
              className={cn(
                'bg-white p-1 rounded-md border-2 border-[#c1c2c2] shadow-sm w-7 h-7',
                props.inline ? 'm-2' : 'mr-2',
              )}
            />
          </div>
        </>
      )}
      {props.children}
    </button>
  )
}
