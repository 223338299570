import { forwardRef, useImperativeHandle, useRef } from 'react'
import { cn } from '@utils/css'
import { Icon } from '@drdropin-tech/theseus'
import { useTranslation } from '@hooks'
import { logsink } from '@lib/logsink'

interface Props {
  className?: string
  videoClassName?: string
  hideMobileFrame?: boolean
  error?: Error | boolean
}

export interface VideoFrameHandle {
  audioEl: HTMLVideoElement | null
  videoEl: HTMLAudioElement | null
}

export default forwardRef<VideoFrameHandle, Props>(function VideoFrame(
  props,
  ref,
) {
  const { translations } = useTranslation()
  const videoRef = useRef<HTMLVideoElement>(null)
  const audioRef = useRef<HTMLVideoElement>(null)

  useImperativeHandle(ref, () => ({
    videoEl: videoRef.current,
    audioEl: audioRef.current,
  }))

  if (props.error) {
    if (props.error instanceof Error) {
      logsink.error(props.error, 'VideoFrame')
    }

    return (
      <div
        className={cn(
          'flex flex-col items-center justify-center gap-6 p-10 w-full',
          'bg-[#FFE2E0] text-[#BA3C3C]', // TODO: theseus colors
          props.className,
        )}
      >
        <Icon name="CameraOff" />
        {translations.error.camera}
      </div>
    )
  }

  return (
    <div
      className={cn(
        'inline-grid place-items-center',
        'grid-cols-1 grid-rows-1',
        'flex-1',
        props.className,
      )}
    >
      <video
        className={cn(
          'bg-black/20 row-[1_/_-1] col-[1_/_-1] h-full object-cover',
          props.videoClassName,
        )}
        ref={videoRef}
        playsInline
        autoPlay
        muted
      />
      <audio ref={audioRef} autoPlay />

      {!props.hideMobileFrame && (
        <div
          className={cn(
            'row-[1_/_-1] col-[1_/_-1]',
            'box-content border-[12px] border-black/70 rounded-3xl w-min h-full z-10 aspect-inverse-video',
          )}
        />
      )}
    </div>
  )
})

export { default as TwilioLocalVideoFrame } from './TwilioLocalVideoFrame'
export { default as TwilioVideoFrame } from './TwilioVideoFrame'
