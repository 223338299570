interface Opts {
  all?: boolean
  video?: boolean
  audio?: boolean
}

export default function cleanup(
  stream?: MediaStream,
  { all = true, video, audio }: Opts = {},
) {
  if (!stream) return

  let tracks: MediaStreamTrack[] = []

  // Cleanup all unless other params are explicitly set
  if (video !== undefined || audio !== undefined) all = false

  if (video) tracks = stream.getVideoTracks()
  if (audio) tracks = stream.getAudioTracks()
  if (all) tracks = stream.getTracks()

  for (const track of tracks) {
    track.stop()
    stream.removeTrack(track)
  }
}
