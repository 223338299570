import { UserRoles } from './types/auth'
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()

const config = {
  environment:
    (process.env.NODE_ENV as string) || process.env.NEXT_PUBLIC_ENV || '',
  coreApi: process.env.NEXT_PUBLIC_API_URL || '',
  socketAppKey: process.env.NEXT_PUBLIC_SOCKET_APP_KEY || '',
  prescriptionWeb: process.env.NEXT_PUBLIC_PRESCRIPTION_WEB_URL || '',
  posthogApiKey: process.env.NEXT_PUBLIC_POSTHOG_API_KEY || '',
  posthogEnabled: publicRuntimeConfig.posthogEnabled === 'true',
  release: process.env.RELEASE || '',
  refreshTokenInterval: Number(publicRuntimeConfig.refreshTokenInterval) || 300,
  prescriptionRoles: [
    UserRoles.Professional,
    UserRoles.Nurse,
    UserRoles.Dermatologist,
  ] as UserRoles[],
}

export default config
