import * as React from 'react'
import { usePersistent } from '@hooks'

export interface IntersectionCallback {
  (
    entry: IntersectionObserverEntry,
    observer: Parameters<IntersectionObserverCallback>[1],
  ): ReturnType<IntersectionObserverCallback>
}

export default function useIntersectionObserver(
  cb: IntersectionCallback,
  { root, rootMargin, threshold }: IntersectionObserverInit = {},
) {
  const ref = React.useRef<HTMLElement>()
  const cbRef = React.useRef<IntersectionCallback>(cb)
  const [mounted, setMounted] = React.useState(false)

  // Prevent unecessary re-renders if array is passed
  threshold = usePersistent(threshold ?? 0)

  React.useEffect(() => {
    cbRef.current = cb
  }, [cb])

  const observer = React.useMemo(() => {
    if (!mounted) return

    return new IntersectionObserver(
      (entries, observer) => cbRef.current(entries[0], observer),
      { root, rootMargin, threshold },
    )
  }, [root, rootMargin, threshold, mounted])

  React.useEffect(() => {
    setMounted(true)
    if (ref.current) observer?.observe(ref.current)

    return () => observer?.disconnect()
  }, [observer])

  const setRef = React.useCallback(
    (el: HTMLElement | null) => {
      if (ref.current) observer?.unobserve(ref.current)
      if (el) {
        observer?.observe(el)
        ref.current = el
      }
    },
    [observer],
  )

  return [ref, setRef] as const
}
