import { Icon, IconName } from '@drdropin-tech/theseus'
import useIsLoggedIn from '@hooks/useIsLoggedIn'
import { useUser } from '@hooks/useSession'
import { useTranslation } from '@hooks'
import { UserRoles } from '@lib/types/auth'
import { validateRoles } from '@utils/auth'
import { cn } from '@utils/css'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useContext } from 'react'
import Context from './Context'

type NavigationLink = {
  name: string
  href: string
  icon?: IconName
  roles?: UserRoles[]
  count?: 'prescription' | 'consultation' | 'history' | 'myConsultation' | 'consultationPlusMyConsultation'
  auth?: true | 'never'
  className?: string
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
  children?: NavigationLink[]
  parentLink?: NavigationLink
}

export default function NavLink(props: NavigationLink) {

  const { route } = useRouter()
  const { translate } = useTranslation()
  const { isNarrow, count } = useContext(Context)
  const user = useUser()
  const loggedIn = useIsLoggedIn()

  if (
    (props.roles && !validateRoles(user, props.roles)) ||
    (props.auth === true && !loggedIn) ||
    (props.auth === 'never' && loggedIn)
  )
    return null

  const isCurrent = route === props.href
  const hasCount = !isNaN(Number(props.count && count[props.count]))
  const counter = props.count && count[props.count]

  if (props.children && props.children.length > 0) {
    const childIsCurrent = props.children.some(child => child.href === route)
    return (
      <ul>
        <li>
          <Link href={props.children[0].href} className={cn(
            'text-sm font-medium text-moss-green',
            'flex items-center px-4 py-2 rounded-lg',
            childIsCurrent && 'bg-light-mint',
            isNarrow ? 'gap-4' : 'gap-2',
            props.className,
          )}>
            {props.icon && <Icon width={20} height={20} name={props.icon} />}
            {!isNarrow && translate(`nav.${props.name}`)}
            {hasCount && (
              <div
                className={cn(
                  'ml-auto py-1 px-2 rounded-md text-xs font-medium',
                  !childIsCurrent && 'bg-black/10',
                )}
              >
                {counter}
              </div>
            )}
          </Link>
        </li>
        {childIsCurrent && (
          <li className="mt-1">
            <div className="flex">
              <div className="flex-none w-6 border-r-2 border-black/10 mr-2"></div>
              <div className="flex-1">
                <ul>
                  {props.children?.map(child => {
                    return (
                      <li
                        key={`nl-child-${child.name}`}
                        className={cn(
                          "border-2 rounded-lg",
                          child.href !== route && "border-transparent",
                          child.href === route && "border-black/10"
                        )}
                      >
                        <NavLink parentLink={props} {...child} />
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </li>
        )}
      </ul>
    )
  }

  return (
    <Link
      href={props.href} onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        props.onClick && props.onClick(e)
      }}
      className={cn(
        'text-sm font-medium text-moss-green',
        'flex items-center px-4 py-2 rounded-lg',
        (!props.parentLink && isCurrent) && 'bg-light-mint',
        isNarrow ? 'gap-4' : 'gap-2',
        props.className,
      )}
      passHref
    >
      {props.icon && <Icon width={20} height={20} name={props.icon} />}
      {!isNarrow && translate(`nav.${props.name}`)}
      {hasCount && (
        <div
          className={cn(
            'ml-auto py-1 px-2 rounded-md text-xs font-medium',
            (!props.parentLink && !isCurrent) && 'bg-black/10',
          )}
        >
          {counter}
        </div>
      )}
    </Link>
  )
}
