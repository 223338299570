import verticals from '@lib/verticals'
import { AppProps } from 'next/app'
import { AuthProps } from './auth'

export type Vertical = typeof verticals[number]

export const languages = ['no', 'en'] as const
export type Language = typeof languages[number]

export interface CustomAppProps<T> extends AppProps<T> {
  Component: AppProps<T>['Component'] & {
    auth?: boolean | AuthProps
  }
}
