import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

export interface DeviceSettings {
  id?: string
}

export interface Settings extends Record<MediaDeviceKind, DeviceSettings> {
  setDevice: (kind: MediaDeviceKind, id: string | undefined) => void
}

export default create<Settings>()(
  devtools(
    persist(
      (set) => ({
        videoinput: {},
        audioinput: {},
        audiooutput: {},

        setDevice(kind, id) {
          set((state) => ({ [kind]: { ...state[kind], id } }))
        },
      }),
      { name: 'media-settings-storage' },
    ),
  ),
)
