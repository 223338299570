import { create } from 'zustand'
import { sortDevices } from '@utils'

export interface DeviceStore {
  devices: Record<string, MediaDeviceInfo>
  setDevices: (payload: MediaDeviceInfo[]) => void
  derived: { sorted: ReturnType<typeof sortDevices> }
}

const useDeviceStore = create<DeviceStore>((set, get) => ({
  devices: {},
  setDevices(payload) {
    set({
      devices: Object.fromEntries(
        /*
          Filter out incomplete deviceData and duplicate devices
          with colliding "default" ids (Chrome/Opera)
          https://stackoverflow.com/a/65366422/5754578
          https://stackoverflow.com/a/53308831
        */
        payload
          .filter((e) => e.deviceId && e.deviceId !== 'default' && e.label)
          .map((d) => [d.deviceId, d]),
      ),
    })
  },

  derived: {
    get sorted() {
      return sortDevices(Object.values(get().devices))
    },
  },
}))

export default useDeviceStore
