export default function DrDropinLogoSymbol() {
  return (
    <svg
      width="47"
      height="47"
      viewBox="0 0 47 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M44.8015 22.1281C44.0437 22.1281 43.4296 22.7422 43.4296 23.5C43.4296 26.897 40.664 29.6626 37.267 29.6626H31.039C30.2812 29.6626 29.6671 30.2767 29.6671 31.0345V37.2625C29.6671 40.6595 26.9015 43.4251 23.5045 43.4251C20.1074 43.4251 17.3418 40.6595 17.3418 37.2625V31.0345C17.3418 30.2767 16.7277 29.6626 15.9699 29.6626C15.2121 29.6626 14.598 30.2767 14.598 31.0345V37.2625C14.598 42.1752 18.5961 46.1689 23.5045 46.1689C28.4172 46.1689 32.4109 42.1708 32.4109 37.2625V32.4064H37.267C42.1797 32.4064 46.1734 28.4083 46.1734 23.5C46.1734 22.7422 45.5593 22.1281 44.8015 22.1281Z"
        fill="#78CBC2"
      />
      <path
        d="M23.5 0.831055C18.5873 0.831055 14.5936 4.82916 14.5936 9.73749V14.5936H9.73749C4.8248 14.5936 0.831055 18.5917 0.831055 23.5C0.831055 24.2578 1.44514 24.8719 2.20295 24.8719C2.96076 24.8719 3.57484 24.2578 3.57484 23.5C3.57484 20.1029 6.34042 17.3374 9.73749 17.3374H15.9655C16.7233 17.3374 17.3374 16.7233 17.3374 15.9655V9.73749C17.3374 6.34042 20.1029 3.57485 23.5 3.57485C26.8971 3.57485 29.6626 6.34042 29.6626 9.73749V15.9655C29.6626 16.7233 30.2767 17.3374 31.0345 17.3374C31.7923 17.3374 32.4064 16.7233 32.4064 15.9655V9.73749C32.4064 4.8248 28.4127 0.831055 23.5 0.831055Z"
        fill="#2F4F4E"
      />
    </svg>
  )
}
