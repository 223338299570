import { SessionStatus } from '@lib/types/auth'
import { Session } from 'next-auth'
import { useSession } from 'next-auth/react'

type TSession = {
  data: Session
  status: string
}

export const useIDToken = () => {
  const { data } = (useSession() as TSession) || {}
  return data?.idToken
}
export const useRoles = () => {
  const { data } = (useSession() as TSession) || {}
  return data?.user?.roles
}
export const useUserId = () => {
  const { data } = (useSession() as TSession) || {}
  return data?.user?.userId
}
export const useUser = () => {
  const { data } = (useSession() as TSession) || {}
  return data?.user
}
export const useIsAuthenticated = () => {
  const { status } = (useSession() as TSession) || {}
  return status === SessionStatus.Authenticated
}
