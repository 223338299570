import { useIDToken, useUser } from '@hooks/useSession'
import config from '@lib/config'
import { UserRoles } from '@lib/types/auth'
import {
  IPrescription,
  PrescriptionStatus,
  TPrescriptions,
} from '@lib/types/prescriptions'
import { validateRoles } from '@utils/auth'
import { isDev } from '@utils/common'
import { getInstance } from 'api'
import { AxiosError } from 'axios'
import { useMutation, useQuery, useQueryClient } from 'react-query'

const fetchPrescription = (id: string, jwt: string): Promise<IPrescription> => {
  return getInstance({ jwt })
    .get(`${config.prescriptionWeb}/api/prescription/get/${id}`)
    .then((res) => res.data.req)
}

export const usePrescriptionQuery = (id: string) => {
  const idToken = useIDToken()
  return useQuery<IPrescription, AxiosError>(
    ['prescription', id],
    () => fetchPrescription(id, idToken),
    { enabled: !!idToken, refetchOnWindowFocus: !isDev },
  )
}

const fetchPrescriptions = (
  statuses: PrescriptionStatus[],
  jwt: string,
  user: ReturnType<typeof useUser>,
): Promise<IPrescription[]> => {
  // exit if this user does not have access to this api
  if (!validateRoles(user, config.prescriptionRoles)) {
    return Promise.resolve([])
  }

  const query = new URLSearchParams({
    version: 'editor',
    statuses: statuses.map((s) => s.toString()).join(','),
  })
  return getInstance({ jwt })
    .get(`${config.prescriptionWeb}/api/prescription/list?${query.toString()}`)
    .then((res) => res.data.req)
}

export const usePrescriptionsQuery = (statuses: PrescriptionStatus[]) => {
  const user = useUser()
  const idToken = useIDToken()
  return useQuery<TPrescriptions, AxiosError>(
    ['prescriptions', { statuses }],
    () => fetchPrescriptions(statuses, idToken, user),
    { enabled: !!idToken, staleTime: 60 * 1000, refetchOnWindowFocus: !isDev },
  )
}

// Default hook for fetching prescriptions
export const usePrescriptions = (
  statuses: PrescriptionStatus[] = [PrescriptionStatus.pending],
  filter?: (p: IPrescription) => boolean,
) => {
  const user = useUser()
  const filterRoles = (prescription: IPrescription) => {
    // All prescription should have a role in perfect world
    // by default, only users [UserRoles.Professional, UserRoles.Admin] can handle prescriptions without roles
    if (!prescription.roles) {
      return validateRoles(user, [UserRoles.Admin, UserRoles.Professional])
    }
    if (
      validateRoles(user, [
        ...(prescription.roles as UserRoles[]),
        UserRoles.Admin,
      ])
    ) {
      return true
    }
    return false
  }
  const { data, ...rest } = usePrescriptionsQuery(statuses)
  const filteredData = filter
    ? data?.filter((p) => {
        return filter(p) && filterRoles(p)
      })
    : (data || []).filter(filterRoles)
  return { data: filteredData, ...rest }
}

export function usePrescription(id: string) {
  return usePrescriptionQuery(id)
}

const claimPrescription = (id: string, jwt: string) => {
  return getInstance({ jwt })
    .put(`${config.prescriptionWeb}/api/prescription/${id}/claim`)
    .then((res) => res.data.req)
}

export const useClaimPrescription = (
  onSuccess?: (id: string) => void,
  onError?:
    | ((
        error: Error,
        variables: string,
        context: unknown,
      ) => void | Promise<unknown>)
    | undefined,
) => {
  const idToken = useIDToken()
  const queryClient = useQueryClient()
  return useMutation((id: string) => claimPrescription(id, idToken), {
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries(['prescriptions'])
      await queryClient.invalidateQueries(['prescription', variables])
      onSuccess?.(variables)
    },
    onError,
  })
}

const unclaimPrescription = (id: string, jwt: string) => {
  return getInstance({ jwt })
    .put(`${config.prescriptionWeb}/api/prescription/${id}/unclaim`)
    .then((res) => res.data.req)
}

export const useUnclaimPrescription = (onSuccess?: (id: string) => void) => {
  const idToken = useIDToken()
  const queryClient = useQueryClient()
  return useMutation((id: string) => unclaimPrescription(id, idToken), {
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries(['prescriptions'])
      await queryClient.invalidateQueries(['prescription', variables])
      onSuccess?.(variables)
    },
  })
}

const approvePrescription = (id: string, jwt: string) => {
  return getInstance({ jwt })
    .put(`${config.prescriptionWeb}/api/prescription/${id}/status/approved`)
    .then((res) => res.data.req)
}

export const useApprovePrescription = (onSuccess?: (id: string) => void) => {
  const idToken = useIDToken()
  const queryClient = useQueryClient()
  return useMutation((id: string) => approvePrescription(id, idToken), {
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries(['prescriptions'])
      await queryClient.invalidateQueries(['prescription', variables])
      onSuccess?.(variables)
    },
  })
}

const rejectPrescription = (id: string, jwt: string) => {
  return getInstance({ jwt })
    .put(`${config.prescriptionWeb}/api/prescription/${id}/status/refused`)
    .then((res) => res.data.req)
}

export const useRejectPrescription = (onSuccess?: (id: string) => void) => {
  const idToken = useIDToken()
  const queryClient = useQueryClient()
  return useMutation((id: string) => rejectPrescription(id, idToken), {
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries(['prescriptions'])
      await queryClient.invalidateQueries(['prescription', variables])
      onSuccess?.(variables)
    },
  })
}
