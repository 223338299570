import { UserRoles } from '@lib/types/auth'
import { useSession } from 'next-auth/react'
import * as React from 'react'
import type { ComponentType } from 'react'

const requireAuth = <P extends object>(
  Component: ComponentType<P>,
  roles: UserRoles[] = [],
) =>
  function RequireAuth(props: P) {
    const { data: session } = useSession()
    return session &&
      (!roles ||
        roles.length === 0 ||
        (session.user.roles || []).filter((role) => roles.includes(role))
          .length > 0) ? (
      <Component {...(props as P)} />
    ) : null
  }

export default requireAuth
