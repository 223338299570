import { UserRoles, validRoles } from '@lib/types/auth'
import { getInstance } from 'api'
import jwtDecode from 'jwt-decode'
import { User } from 'next-auth'
import { JWT } from 'next-auth/jwt'
import NextAuth0Provider from 'next-auth/providers/auth0'
import { signOut } from 'next-auth/react'
import { posthog } from 'posthog-js'
import { isProd, isStaging } from './common'
import * as Sentry from '@sentry/nextjs'
import Logger from '@lib/logger'

export const validateRoles = (user: User, valid: UserRoles[] = validRoles) => {
  return !!user?.roles?.some((value) => valid.includes(value as UserRoles))
}

export const logOut = () => {
  if (isProd || isStaging) {
    // reset posthog connection
    posthog.reset()
  }
  signOut()
}

export const getExpirationFromJWT = (jwt?: string, fallback: number = 0) => {
  if (jwt) {
    try {
      const decoded = jwtDecode<Record<string, any>>(jwt)
      return typeof decoded.exp === 'number' ? decoded.exp * 1000 : fallback
    } catch (e) {
      Sentry.captureException(e)
    }
  }
  return fallback
}

export async function refreshToken(token: JWT): Promise<JWT> {
  try {
    if (!token.refreshToken) {
      throw new Error('Missing refresh token')
    }
    const url = new URL(`https://${process.env.AUTH0_DOMAIN}`)
    url.pathname = '/oauth/token'
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        grant_type: 'refresh_token',
        client_id: process.env.AUTH0_CLIENT_ID ?? '',
        client_secret: process.env.AUTH0_CLIENT_SECRET ?? '',
        refresh_token: token.refreshToken,
      })
    })
    // forward error
    if (!response.ok) {
      const data = await response.json()
      Logger.error({
        status: response.status,
        statusText: response.statusText,
        data,
        tokenUser: token.user,
      }, 'refreshToken error')
      throw new Error(`RefreshTokenError: ${data.description}`, {
        cause: data.error,
      })
    }

    const data = await response.json()
    Logger.info({ responseData: data }, 'refreshToken response')

    const {
      id_token: idToken,
      refresh_token: refreshToken,
      expires_in,
    } = data

    const expiresAt = Date.now() + expires_in * 1000
    const idTokenExpires = getExpirationFromJWT(idToken, expiresAt)
    return {
      ...token,
      idToken,
      idTokenExpires,
      refreshToken: refreshToken ?? token.refreshToken, // Fall back to old refresh token
      error: ''
    }
  } catch (e: unknown) {
    const error = e as Error
    Logger.error({
      error: error.message,
      cause: error.cause,
    }, 'refresh access token error')
    Sentry.captureException(e)
    throw e;
  }
}

export const Auth0Provider = NextAuth0Provider({
  clientId: process.env.AUTH0_CLIENT_ID ?? '',
  clientSecret: process.env.AUTH0_CLIENT_SECRET ?? '',
  issuer: `https://${process.env.AUTH0_DOMAIN ?? ''}`,
  checks: ['pkce', 'state'],
  wellKnown: `https://${process.env.AUTH0_DOMAIN ?? ''}/.well-known/openid-configuration`,
  authorization: {
    params: {
      prompt: 'login',
      connection: 'Signicat2022', // 'Idfy',
      scope: 'openid profile email offline_access',
    },
  },
  // convert user object to our preferred format
  profile(profile: any) {
    return {
      id: profile.sub,
      name: profile.name,
      nickname: profile.nickname,
      firstName: profile.given_name,
      lastName: profile.family_name,
      image: profile.picture,
      ...(profile[process.env.AUTH0_AUDIENCE ?? '-'] ?? {}),
    }
  },
})
