import { useBreakpoints, useCounter, usePersistent } from '@hooks'
import { cn } from '@utils/css'
import Context from './Context'
import Footer from './Footer'
import Header from './Header'
import links from './links'
import NavLink from './NavLink'

export default function Nav() {
  const breakpoints = useBreakpoints()
  const isNarrow = breakpoints.isGreater().md && breakpoints.isLesser().lg

  const count = useCounter()
  const context = usePersistent({ isNarrow, count })

  return (
    <Context.Provider value={context}>
      <div className={cn('flex flex-col bg-white', isNarrow ? '' : 'w-80')}>
        <Header />
        <div
          className={cn(
            'py-2 flex-1 flex flex-col gap-1',
            isNarrow ? 'px-2' : 'px-4',
          )}
        >
          {links
            .filter((link) => !link.footer)
            .map((props) => (
              <NavLink key={props.name} {...props} />
            ))}

          {links
            .filter((link) => link.footer)
            .map((props, i) => (
              <NavLink
                key={props.name}
                className={cn(i == 0 && 'mt-auto')}
                {...props}
              />
            ))}
        </div>
        <Footer />
      </div>
    </Context.Provider>
  )
}
