import useIdleTime from '@hooks/useIdleTime'
import { useSession } from 'next-auth/react'
import { useEffect } from 'react'
import { useExpiry } from '@hooks/useExpiry'
import { logsink } from '@lib/logsink'

export const DetectIdleTime = () => {
  const { data: session } = useSession()
  const expiry = useExpiry(session?.idTokenExpires)
  // sign out when idle time is reached
  useIdleTime()
  // just a hook for reporting when a session has expired
  useEffect(() => {
    if (expiry.exp < 0) {
      logsink.warn({ session, expiry }, 'DetectIdleTime: session has expired')
    }
  }, [expiry, session])

  return null
}

export default DetectIdleTime
